import { Oasis } from '@oasis/sdk';
import { Platforms } from '@oasis/utils';
import { createContext, useContext, useMemo, type ReactNode } from 'react';

interface ProjectContextValue {
  hubId: string;
  projectId: string;
  platform: Platforms;
  region: string;
}

export const ProjectContext = createContext<ProjectContextValue>({
  hubId: '',
  projectId: '',
  platform: 'acc',
  region: Oasis.Storage.get('region') || 'US',
});

export function ProjectContextProvider(props: { projectId: string; children: ReactNode }) {
  const $projects = Oasis.Projects.useStore();

  const contextValue = useMemo(() => {
    const projectMeta = $projects.metaCache.get(props.projectId);

    if (projectMeta) {
      return {
        projectId: props.projectId,
        hubId: projectMeta.hubId,
        platform: projectMeta.platform,
        region: projectMeta.region,
      };
    }
  }, [props.projectId, $projects.metaCache]);

  if (!contextValue) {
    return null;
  }

  return <ProjectContext.Provider value={contextValue}>{props.children}</ProjectContext.Provider>;
}

export function useProjectContext() {
  const ctx = useContext(ProjectContext);

  if (!ctx.hubId) {
    throw new Error('`hubId` must be set in ProjectContext.');
  }

  if (!ctx.projectId) {
    throw new Error('`projectId` must be set in ProjectContext.');
  }

  return useContext(ProjectContext);
}
