import { CryptoUtils } from '@oasis/utils';
import ky, { BeforeRequestHook } from 'ky';
import { ReleaseChannel } from '../../services/releases/releases.types';
import { createRefreshHook, createSetHeadersHook } from './utils';

interface InitParams {
  baseUrl: string;
  releaseChannel?: ReleaseChannel;
}

export let OasisHttp = ky.create({
  headers: {
    'Content-Type': 'application/json',
  },
  retry: {
    limit: 1,
    statusCodes: [401],
    methods: ['get', 'post', 'put', 'patch', 'delete'],
  },
  timeout: 60_000,
});

export const OasisHttpProvider = {
  accessToken: '',

  init(params: InitParams) {
    // Assign a req id header to each request
    const reqIdHeader: BeforeRequestHook = async request => {
      request.headers.set('Forgexr-Req-Id', CryptoUtils.randomUUID());
    };

    const setHeaders = createSetHeadersHook();
    const refreshSession = createRefreshHook();

    // Extend our base Http client with our auth hooks.
    OasisHttp = OasisHttp.extend({
      prefixUrl: params.baseUrl,
      headers: undefined,
      hooks: {
        beforeRequest: [reqIdHeader, setHeaders],
        beforeRetry: [refreshSession],
      },
    });
  },
};
