import { LogoAutodeskAccIcon, LogoAutodeskBim360Icon, StarFilledIcon, StarIcon } from '@adsk/alloy-react-icon';
import {
  TableCell,
  TableHeader,
  TableHeaderGroup,
  TableRow,
  TableSkeletonLoadingRow,
  useSkeletonLoadingRows,
  useTable,
} from '@adsk/alloy-react-table';
import { Oasis } from '@oasis/sdk';
import { DateUtils } from '@oasis/utils';
import type { Cell, Header } from '@tanstack/react-table';
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { TablePagination } from '~/shared/components/base/table-pagination';
import { Tooltip } from '~/shared/components/base/tooltip';
import { Queries } from '~/shared/hooks/queries';
import type { ProjectWithRegion } from '~/shared/hooks/queries/projects.queries';
import { OasisError } from '~/shared/utils/oasis-error';

const columnHelper = createColumnHelper<ProjectWithRegion & { starred: boolean }>();

interface Props {
  query: string;
}

export function ProjectsTable({ query }: Props) {
  const $session = Oasis.Session.useStore();
  const navigate = useNavigate();

  const [rowSelection, setRowSelection] = useState({});
  const [columnSorting, setColumnSorting] = useState<SortingState>([{ id: 'createdAt', desc: true }]);

  const projects = Queries.Projects.useListAllProjects();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('starred', {
        enableHiding: true,
      }),
      columnHelper.accessor('platform', {
        size: 100,
        maxSize: 120,
        header: 'Type',
        cell: info => (
          <p className="text-blue-logo">
            {
              {
                acc: <LogoAutodeskAccIcon />,
                bim360: <LogoAutodeskBim360Icon className="text-charcoal-500" />,
              }[info.getValue()]
            }
          </p>
        ),
        enableSorting: false,
        enableResizing: false,
      }),

      columnHelper.accessor('name', {
        size: 300,
        header: 'Name',
        cell: info => {
          const value = info.getValue();
          const disabled = info.row.original.platform === 'bim360';
          const projectId = info.row.original.id;
          const isStarred = $session.favoriteProjectIds.includes(projectId);

          return (
            <div className="flex max-w-full items-center pr-5">
              <Tooltip
                className="ml-4 max-w-72"
                placement="right"
                content={
                  disabled && (
                    <>
                      <p className="font-bold">Unsupported project type.</p>
                      <p>Only ACC projects supported at this time. BIM360 projects are not supported.</p>
                    </>
                  )
                }
              >
                <p className="flex-1 truncate">{value}</p>
              </Tooltip>

              {!disabled && (
                <button
                  type="button"
                  className={clsx(
                    'ml-2',
                    isStarred
                      ? 'text-yellorange-500  '
                      : 'opacity-0 group-hover:opacity-20 group-hover:hover:opacity-75'
                  )}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    Oasis.Users.toggleFavoriteProject(projectId);
                  }}
                >
                  {isStarred ? <StarFilledIcon /> : <StarIcon />}
                </button>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('accountName', {
        size: 300,
        header: 'Account',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('createdAt', {
        size: 200,
        header: 'Created At',
        cell: info => DateUtils.getFriendlyFormat(info.getValue()),
      }),
      columnHelper.accessor('region', {
        size: 200,
        header: 'Account region',
        cell: info => {
          const value = info.getValue();

          switch (value) {
            case 'US':
              return 'United States (US)';
            case 'EMEA':
              return 'Europe, Middle East, and Africa (EMEA)';
            case 'AUS':
              return 'Australia (AUS)';
            default:
              return 'Unknown';
          }
        },
        enableSorting: false,
        enableResizing: false,
      }),
    ];
  }, [$session.favoriteProjectIds]);

  const data = useMemo(() => {
    const items = projects.data || [];
    const q = query.toLowerCase();

    return items
      .filter(item => item.name.toLowerCase().includes(q))
      .map(item => ({
        ...item,
        starred: $session.favoriteProjectIds.includes(item.id),
      }));
  }, [projects.data, query, $session.favoriteProjectIds]);

  const sorting = useMemo(() => {
    return [{ id: 'starred', desc: true }, ...columnSorting];
  }, [columnSorting]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      columnVisibility: {
        starred: false,
      },
      sorting: sorting,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setColumnSorting,
    columnResizeMode: 'onEnd',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const { getTableRowProps, getTableCellProps, getTableHeaderGroupProps, getTableHeaderProps } = useTable({
    table,
    enableFullRowClick: true,
  });

  const { getSkeletonLoadingRows, getSkeletonLoadingRowProps } = useSkeletonLoadingRows({
    numberOfRows: 8,
    // @ts-ignore: For some reason @ts-expect-error doesn't work here, but does all other places
    table,
  });

  function getColumnStyles(cell: Cell<(typeof data)[number], unknown> | Header<(typeof data)[number], unknown>) {
    return cell.column.id === 'platform' ? { maxWidth: cell.column.getSize() } : undefined;
  }

  if (projects.error instanceof OasisError) {
    return <OasisErrorState oasisError={projects.error} />;
  }

  if (!projects.isLoading && !query && data.length === 0) {
    return (
      <OasisErrorState
        illustration="buildingConstructionGrey"
        title="No projects to display"
        description={
          <span>
            Contact an administrator to create a project and invite you to it. For more information visit the{' '}
            <a
              href="https://construction.autodesk.com/support/"
              className="text-blue-700 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Help Site
            </a>
          </span>
        }
      />
    );
  }

  if (query && data.length === 0) {
    return (
      <OasisErrorState
        illustration="folderPhotoGrey"
        title="No projects match your search terms"
        description="Check your search terms and try again"
      />
    );
  }

  return (
    <div className="w-full h-full flex flex-col items-start">
      <div className="w-full h-full overflow-auto">
        <table className="min-w-full">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableHeaderGroup key={headerGroup.id} {...getTableHeaderGroupProps(headerGroup)}>
                {headerGroup.headers.map(header => (
                  <TableHeader key={header.id} {...getTableHeaderProps(header)} style={getColumnStyles(header)} />
                ))}
              </TableHeaderGroup>
            ))}
          </thead>
          <tbody>
            {projects.isLoading &&
              getSkeletonLoadingRows().map(skeletonRow => (
                <TableSkeletonLoadingRow key={skeletonRow.id} {...getSkeletonLoadingRowProps(skeletonRow)} />
              ))}

            {table.getRowModel().rows.map(row => {
              const disabled = row.original.platform === 'bim360';

              return (
                <TableRow
                  key={row.id}
                  {...getTableRowProps(row)}
                  onClick={() => !disabled && navigate(`/projects/${row.original.id}`)}
                  className={clsx('group', disabled && 'pointer-events-none')}
                  disabled={disabled}
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id} {...getTableCellProps(cell)} style={getColumnStyles(cell)} />
                  ))}
                </TableRow>
              );
            })}
          </tbody>
        </table>
      </div>

      <TablePagination table={table} totalRows={projects.data?.length || 0} isLoading={projects.isLoading} />
    </div>
  );
}
