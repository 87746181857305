import { DesktopIcon } from '@adsk/alloy-react-icon';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Mutations } from '~/shared/hooks/mutations';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';
import { workshopScene } from '~/shared/utils/const.asset-urls';
import { Button } from '../../../../shared/components/base/button';
import { HeadsetIcon } from '../../../../shared/components/icons/headset-icon';
import { useOpenModal } from '../../../../shared/hooks/use-modal';
import { WorkshopActiveUsers } from '../workshop-active-users';
import { DropdownButtons } from './dropdown-buttons';

const cache = new Map<string, string>();

interface Props {
  workshopId: string;
  workshopName?: string;
  hideButtons?: boolean;
  hideActiveUsers?: boolean;
}

export function WorkshopThumbnail(props: Props) {
  const $env = Oasis.Env.useStore();
  const [enableDesktop, showCollaborativeWebViewer] = useFeatureFlags([
    '5465-enter-on-desktop',
    '241127-7456-web-viewer',
  ]);
  const openModal = useOpenModal();
  const openWorkshop = Mutations.Workshops.useOpenWorkshopOnDevice();

  const ref = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState(cache.get(props.workshopId) ?? undefined);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    let controller: AbortController | undefined;

    async function fetchThumbnail() {
      controller = new AbortController();
      const res = await Oasis.Workshops.getWorkshopThumbnail(props.workshopId, { signal: controller.signal });

      if (!res.ok) {
        return setUrl(workshopScene);
      }

      cache.set(props.workshopId, res.value);
      setUrl(res.value);
    }

    function callback(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          fetchThumbnail();
          observer.disconnect();
        }
      }
    }

    const observer = new IntersectionObserver(callback);
    observer.observe(ref.current);

    return () => {
      controller?.abort();
      observer.disconnect();
    };
  }, [props.workshopId]);

  return (
    <div
      ref={ref}
      className="group relative w-full aspect-video bg-cover bg-charcoal-100"
      style={{ backgroundImage: `url(${workshopScene})` }}
    >
      <img
        src={url}
        alt="Workshop thumbnail"
        loading="lazy"
        onLoad={() => setShow(true)}
        className={clsx(
          'block w-full aspect-video object-cover transition-opacity duration-200',
          show ? 'opacity-100' : 'opacity-0'
        )}
      />

      {!props.hideActiveUsers && showCollaborativeWebViewer && (
        <div className="absolute bottom-0 left-0 px-2 pt-2 pb-2">
          <WorkshopActiveUsers workshopId={props.workshopId} size="medium" hideEmptyList />
        </div>
      )}

      {!props.hideButtons && (
        <div
          data-testid="button-container"
          className={clsx([
            'absolute inset-0 flex items-center justify-center bg-[#022C40] bg-opacity-40',
            'opacity-0 group-hover:opacity-100',
          ])}
        >
          {/* In VR we only show the "Enter" button so they can enter a workshop */}
          {$env.isVr && (
            <Button
              data-testid="vr-enter-button"
              onClick={e => {
                e.stopPropagation();
                openWorkshop.mutate({ device: Oasis.Env.store.vrTargetDevice, id: props.workshopId });
              }}
            >
              <div className="flex items-center">
                <HeadsetIcon className="-ml-1 mr-2 w-6 h-6" /> Enter
              </div>
            </Button>
          )}

          {/* On web/desktop they can "Enter on desktop" when it's enabled */}
          {!$env.isVr && enableDesktop && (
            <DropdownButtons
              primaryAction={{
                children: (
                  <div className="flex items-center">
                    <HeadsetIcon className="mr-2 w-6 h-6" /> Enter on headset
                  </div>
                ),
                onClick() {
                  openModal('open-workshop-headset', props);
                },
              }}
              secondaryActions={[
                {
                  children: (
                    <div className="flex items-center">
                      <DesktopIcon size={24} className="mr-2" /> Enter on desktop
                    </div>
                  ),
                  onClick() {
                    openModal('open-workshop-desktop', props);
                  },
                },
              ]}
            />
          )}

          {/* On web/desktop they can only "Enter on headset" if desktop is not enabled */}
          {!$env.isVr && !enableDesktop && (
            <Button
              onClick={e => {
                e.stopPropagation();
                openModal('open-workshop-headset', props);
              }}
            >
              <div className="flex items-center">
                <HeadsetIcon className="-ml-1 mr-2 w-6 h-6" /> Enter on headset
              </div>
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
