let toolInstance: any;

export function getFollowUserTool(): any {
  class FollowUserTool extends Autodesk.Viewing.ToolInterface {
    names: string[];

    constructor() {
      super();
      this.names = ['followUser'];
    }
  }

  // @ts-ignore
  Autodesk.Viewing.GlobalManagerMixin.call(FollowUserTool.prototype);

  if (!toolInstance) {
    toolInstance = new FollowUserTool();
  }
  return toolInstance;
}
