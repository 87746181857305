import { Oasis } from '@oasis/sdk';
import { FileUtils } from '@oasis/utils';
import { Dialog, DialogContent } from '@radix-ui/react-dialog';
import clsx from 'clsx';
import React from 'react';
import { useParams } from 'react-router-dom';
import { deepClone, useProxy } from 'valtio/utils';
import { Tooltip } from '~/shared/components/base/tooltip';
import { Queries } from '~/shared/hooks/queries';
import { CollaborationExtensionState, ExtensionLoadingState } from '~/shared/hooks/use-collaboration-extension';
import { ConnectionStatus, WorkshopConnection } from '~/shared/hooks/use-workshop-state';

export const getVisibilitySyncStatus = (status: ExtensionLoadingState, connectionStatus: ConnectionStatus) => {
  switch (status) {
    case 'INITIAL':
      return { color: 'blue', text: 'Not In Sync' };
    case 'LOADING':
      return { color: 'yellow', text: 'Loading' };
    case 'LOADED':
      if (connectionStatus === 'CONNECTED') {
        return { color: 'green', text: 'In Sync' };
      } else {
        return { color: 'blue', text: 'Not In Sync' };
      }
    case 'FAILED':
      return { color: 'red', text: 'Failed' };
  }
};

export function ActiveWorkshopBanner() {
  const $session = Oasis.Session.useStore();
  const $env = Oasis.Env.useStore();
  const { status: connectionStatus } = WorkshopConnection.useStore();
  const $collab = useProxy(CollaborationExtensionState);
  const params = useParams() as { projectId: string };

  const activeModel = Queries.Users.useGetActiveModel(params.projectId);

  const [showReasons, setShowReasons] = React.useState(false);

  const viewName = activeModel.data?.activeView?.name;
  const modelName = activeModel.data?.model?.data.attributes.name;
  const displayName = viewName ? viewName : modelName;
  const fullDisplayName = viewName ? `${modelName} - ${viewName}` : modelName;
  const truncatedViewName = displayName && FileUtils.truncateFilenameFromMiddle(displayName, 20, 8);
  const reasons = deepClone($collab.reasons);

  if (connectionStatus === 'CONNECTING' && $collab.status === 'LOADED') {
    reasons.push('Connection lost: The fluid document encountered an issue and is currently disconnected.');
  }

  if ($env.isVrHomespace) {
    return null;
  }

  if ($session.activeWorkshop && params.projectId) {
    const fluidStatus = getVisibilitySyncStatus($collab.status, connectionStatus);

    return (
      <aside className="flex bg-charcoal-900 items-center justify-center px-4">
        <div className="flex items-center">
          {$env.isDevMode && $env.isVr ? (
            <>
              <div
                role="presentation"
                className={`w-3 h-3 rounded-full bg-${fluidStatus.color}-500 mr-2 cursor-pointer`}
                onClick={() => {
                  setShowReasons(showReasons => !showReasons);
                }}
              />

              {
                <Dialog defaultOpen={true} open={!!reasons.length && showReasons} onOpenChange={setShowReasons}>
                  <DialogContent>
                    <div className="absolute top-8 p-4 bg-black rounded-lg justify-center z-40">
                      <div className="flex-1 flex-col max-w-[40rem] ">
                        {reasons.map((reason, i) => {
                          return (
                            <div key={i} className="flex-1 items-center">
                              <p className="text-white text-heading-5 uppercase tracking-wider py-2 mr-1.5 overflow-auto">
                                <span className="font-medium">Reason {i + 1}:</span>
                                {reason}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              }
              <p className="text-white text-heading-5 uppercase tracking-wider truncate leading-none py-2">
                <span className="font-medium mr-1">Model Visibility:</span> {fluidStatus.text}
              </p>

              <div className="mx-5 w-[1px] h-5 bg-charcoal-200"></div>
            </>
          ) : null}

          <div role="presentation" className="w-3 h-3 rounded-full bg-green-500 mr-2" />

          <p className="text-white text-heading-5 uppercase tracking-wider truncate leading-none py-2 mr-1.5">
            <span className="font-medium">Active Workshop:</span>
          </p>

          <Tooltip
            content={$session.activeWorkshop.name}
            placement="bottom-start"
            className="break-all max-w-[16.25rem] mt-2"
          >
            <div className="max-w-[16.25rem]">
              <p className="text-white text-heading-5 uppercase tracking-wider truncate leading-none py-2">
                {$session.activeWorkshop.name}
              </p>
            </div>
          </Tooltip>

          <p className={clsx('mx-1.5', displayName && 'text-white', !displayName && 'text-charcoal-500')}>{'•'}</p>

          <Tooltip content={fullDisplayName} placement="bottom-start" className="break-all max-w-[16.25rem] mt-2">
            <p
              className={clsx(
                'text-heading-5 uppercase tracking-wider leading-none py-2',
                displayName && 'text-white',
                !displayName && 'text-charcoal-500'
              )}
            >
              {truncatedViewName || 'NO FILE SHARED'}
            </p>
          </Tooltip>
        </div>
      </aside>
    );
  }

  return null;
}
