import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppHistory } from '../utils/app-history';

interface ModalParams {
  onboarding: void;
  'pair-headset': { code: string };
  'create-workshop': void;
  'open-workshop-headset': { workshopId: string; workshopName?: string };
  'open-workshop-desktop': { workshopId: string; workshopName?: string };
  'clear-annotations': { workshopId: string };
  'shared-to-workshop': void;
  'add-user-to-workshop': void;
}

type ModalName = keyof ModalParams;
type MaybeParams<T extends ModalName, P = ModalParams[T]> = P extends object ? [params: P] : [params?: void];

export function useModal<T extends ModalName>(name: T) {
  const [searchParams] = useSearchParams();
  const openModal = useOpenModal();
  const navigateToModal = useNavigateToModal();
  const close = useCloseModal();

  const open = useCallback(
    (...params: MaybeParams<T>) => openModal(name, ...params), //
    [name, openModal]
  );

  const navigateTo = useCallback(
    (pathname: string, ...params: MaybeParams<T>) => navigateToModal(pathname, name, ...params), //
    [name, navigateToModal]
  );

  const params = useMemo(() => {
    const params: Record<string, string> = {};

    for (const [key, value] of searchParams) {
      if (key !== 'dialog') {
        params[key] = value;
      }
    }

    return params as ModalParams[T];
  }, [searchParams]);

  return {
    isOpen: searchParams.get('dialog') === name,
    open,
    close,
    params,
    navigateTo,
  };
}

export function useOpenModal() {
  return useCallback(<T extends ModalName>(name: T, ...params: MaybeParams<T>) => {
    AppHistory.searchParams.set('dialog', name);
    const paramsObj = params[0];

    if (paramsObj) {
      for (const key of Object.keys(paramsObj)) {
        const value = paramsObj[key as keyof typeof paramsObj];
        if (value) AppHistory.searchParams.set(key, `${value}`);
      }
    }

    AppHistory.navigate({ search: AppHistory.searchParams.toString() });
  }, []);
}

export function useNavigateToModal() {
  return useCallback(<T extends ModalName>(pathname: string, name: T, ...params: MaybeParams<T>) => {
    AppHistory.searchParams.set('dialog', name);
    const paramsObj = params[0];

    if (paramsObj) {
      for (const key of Object.keys(paramsObj)) {
        const value = paramsObj[key as keyof typeof paramsObj];
        if (value) AppHistory.searchParams.set(key, `${value}`);
      }
    }

    AppHistory.navigate({ pathname, search: AppHistory.searchParams.toString() });
  }, []);
}

export function useCloseModal() {
  return useCallback((deleteAdditionalParams?: string[]) => {
    AppHistory.searchParams.delete('dialog');

    if (deleteAdditionalParams) {
      for (const param of deleteAdditionalParams) {
        AppHistory.searchParams.delete(param);
      }
    }

    AppHistory.navigate({ search: AppHistory.searchParams.toString() });
  }, []);
}
