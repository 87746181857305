import { Oasis } from '@oasis/sdk';
import { Link, useLocation } from 'react-router-dom';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { Tooltip } from '~/shared/components/base/tooltip';
import { HeadsetPlusIcon } from '~/shared/components/icons/headset-plus-icon';
import { useModal } from '~/shared/hooks/use-modal';

export function HeadsetsMenu() {
  const loc = useLocation();
  const from = encodeURIComponent(loc.pathname + loc.search);
  const pairingModal = useModal('pair-headset');

  async function getCode(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    const pairingCode = await Oasis.Session.getPairingCode();

    if (!pairingCode.ok) {
      NotificationManager.push({
        status: 'error',
        content: (
          <p>
            <span className="font-bold">Pairing code</span> failed to generate. Try again.
          </p>
        ),
      });
      return;
    }

    pairingModal.open({ code: pairingCode.value });
  }

  return (
    <Link
      to={`/pair?from=${from}`}
      onClick={getCode}
      className="flex items-center justify-center w-8 text-charcoal-600 hover:text-charcoal-800"
    >
      <Tooltip placement="bottom" content="Pair a headset" className="-mt-1 pointer-events-none">
        <div className="flex items-center h-12">
          <HeadsetPlusIcon className="translate-x-[2px]" />
        </div>
      </Tooltip>
    </Link>
  );
}

// <Popover>
//   <Popover.Trigger className="h-12 text-charcoal-600 hover:text-charcoal-800">
//     <HeadsetIcon className="w-6 h-6" />
//   </Popover.Trigger>
//
//   <Popover.Content side="bottom" align="end" alignOffset={-28} className="w-64">
//     <div className="p-4">
//       <p className="text-heading-4 mb-2">Headset status</p>
//       <p className="text-body-sm text-charcoal-900">
//         You currently do not have a headset paired. Get started in VR by pairing a
//         headset.
//       </p>
//     </div>
//
//     <div className="border-t border-charcoal-200 px-4 py-3 space-x-2">
//       <Button onClick={() => Oasis.Session.loginWithRedirect({ destination: 'pairing-code' })} className="w-full">
//         Pair a headset
//       </Button>
//     </div>
//   </Popover.Content>
// </Popover>
