import Button from '@adsk/alloy-react-button/es/Button';
import Modal from '@adsk/alloy-react-modal/es/Modal';
import clsx from 'clsx';
import { useState } from 'react';
import {
  useWorkshopFluidControls,
  type WorkshopFluidDataType,
} from '~/features/workshops/hooks/use-workshop-fluid-controls';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { Checkbox } from '~/shared/components/forms/checkbox';
import { useModal } from '~/shared/hooks/use-modal';

interface Props {
  workshopId: string;
  workshopName: string;
}

export function ClearAnnotationsModal(props: Props) {
  const modal = useModal('clear-annotations');
  const [selectedOptions, setSelectedOptions] = useState<WorkshopFluidDataType[]>([]);
  const fluidControls = useWorkshopFluidControls(props.workshopId);

  function handleSubmit() {
    if (selectedOptions.length) {
      fluidControls.resetData(...selectedOptions);
    }

    const dataType = selectedOptions.length === 2 ? 'Annotations' : selectedOptions[0];

    NotificationManager.push({
      status: 'success',
      content: (
        <>
          <span className="capitalize font-bold">{dataType}</span> cleared from {props.workshopName}
        </>
      ),
    });

    modal.close();
  }

  return (
    // @ts-expect-error
    <Modal open={modal.isOpen} width={598} className="!h-auto">
      <Modal.Header>
        <div>
          <h1 className="text-heading-1">Clear workshop annotations</h1>
          <p className="text-heading-3 text-charcoal-700 mt-2">
            Select annotations to be deleted from models shared to this workshop. This cannot be undone.
          </p>
        </div>
        <Modal.Close showEscapeLabel={false} onClick={() => modal.close()} className="absolute top-5 right-6" />
      </Modal.Header>

      <Modal.Body className="!py-6 space-y-3">
        <ul className="border border-charcoal-200 rounded-sm">
          {[
            {
              value: 'measurements' as const,
              label: 'Measurements',
              description: '',
              disabled: !fluidControls.hasMeasurements,
            },
            {
              value: 'annotations' as const,
              label: 'Drawings',
              description: 'Includes both handheld and projected drawings.',
              disabled: !fluidControls.hasAnnotations,
            },
          ].map(option => (
            <li key={option.label}>
              <label
                htmlFor={option.label}
                className={clsx(
                  'flex items-center px-4 py-3',
                  option.disabled
                    ? 'cursor-not-allowed text-charcoal-400'
                    : 'cursor-pointer hover:bg-blue-50 focus-within:bg-blue-50'
                )}
              >
                <Checkbox
                  id={option.label}
                  checked={selectedOptions.includes(option.value)}
                  disabled={option.disabled}
                  onChange={state => {
                    if (state) {
                      setSelectedOptions([...selectedOptions, option.value]);
                    } else {
                      setSelectedOptions(selectedOptions.filter(item => item !== option.value));
                    }
                  }}
                />
                <div className="ml-4">
                  <p className="text-label-md">{option.label}</p>
                  {option.description && <p className="text-label-sm opacity-80">{option.description}</p>}
                </div>
              </label>
            </li>
          ))}
        </ul>
      </Modal.Body>

      <Modal.Footer className="flex items-center">
        <div className="flex-1">
          {selectedOptions.length > 0 && (
            <p>
              {selectedOptions.length} option{selectedOptions.length > 1 ? 's' : ''} selected{' '}
              <button className="text-blue-500 ml-2" onClick={() => setSelectedOptions([])}>
                Deselect
              </button>
            </p>
          )}
        </div>
        <div className="flex gap-2">
          <Button
            variant="secondary"
            onClick={() => {
              setSelectedOptions([]);
              modal.close();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={!selectedOptions.length}
            alert
            className="!bg-red-600 active:!bg-red-700"
          >
            Clear selected
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
