import { Oasis } from '@oasis/sdk';
import { NetworkMode, skipToken, useQuery } from '@tanstack/react-query';
import { workshopUsers } from '~/features/workshops/hooks/use-workshop-active-users';
import { OasisError } from '~/shared/utils/oasis-error';

export const workshopsQueries = {
  useListAllWorkshops(projectId: string) {
    return useQuery({
      queryKey: ['workshops', projectId],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Workshops.listWorkshops({
          projectId,
          opts: { signal },
        });

        if (!res.ok) {
          throw new OasisError(res.error, '[Workshops.listAllWorkshops]');
        }

        return res.value;
      },
      enabled: Boolean(projectId), // may be an empty string in some debugging cases
    });
  },

  useFindWorkshopById(params: { workshopId?: string; opts?: { networkMode?: NetworkMode } }) {
    return useQuery({
      queryKey: ['workshops', params.workshopId],
      queryFn: params.workshopId
        ? async () => {
            if (!params.workshopId) {
              Oasis.Logger.error('[Queries.Workshops.useFindWorkshopById] Disable query when workshopId is null.');
              throw new Error('Invalid query.');
            }

            const res = await Oasis.Workshops.findWorkshopById(params.workshopId);

            if (!res.ok) {
              throw new OasisError(res.error, '[Workshops.findWorkshopById]');
            }

            return res.value;
          }
        : skipToken,
      networkMode: params.opts?.networkMode,
    });
  },

  useListActiveUsers(workshopId: string, opts?: { enabled?: boolean }) {
    return useQuery({
      queryKey: ['workshops', workshopId, 'active-users'],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Workshops.listActiveUsers(workshopId, { signal });
        if (!res.ok) throw new OasisError(res.error, '[Workshops.listActiveUsers]');
        workshopUsers.set(workshopId, res.value.results);
        return res.value;
      },
      refetchInterval: 1000 * 10,
      enabled: opts?.enabled,
    });
  },

  useListACCUsers(projectId: string) {
    return useQuery({
      queryKey: ['projects', projectId, 'users'],
      queryFn: async () => {
        const res = await Oasis.Workshops.listACCUsers(projectId);
        if (!res.ok) throw new OasisError(res.error, '[Workshops.listACCUsers]');
        return res.value;
      },
    });
  },

  useListWorkshopMembers(workshopId: string) {
    return useQuery({
      queryKey: ['workshop', workshopId, 'members'],
      queryFn: workshopId
        ? async () => {
            const res = await Oasis.Workshops.listWorkshopMembers(workshopId);
            if (!res.ok) throw new OasisError(res.error, '[Workshops.listWorkshopMembers]');
            return res.value;
          }
        : skipToken,
    });
  },

  useGetWorkshopSettings(workshopId?: string) {
    return useQuery({
      queryKey: ['workshop', workshopId, 'settings'],
      queryFn: !workshopId
        ? skipToken
        : async () => {
            if (!workshopId) {
              Oasis.Logger.error('[Queries.Workshops.useGetWorkshopSettings] Disable query when workshopId is null.');
              throw new Error('Invalid query.');
            }

            const res = await Oasis.Workshops.getWorkshopSettings(workshopId);
            if (!res.ok) throw new OasisError(res.error, '[Workshops.getWorkshopSettings]');
            return res.value;
          },
      // @TODO current workaround for getting the latest settings including updated model urn,
      // when user pushes a new model to stage. This should be done more efficiently in the future
      // using mqtt or fluid.
      // Note useGetActiveModel seems to be more expensive as it makes more calls in the background.
      refetchInterval: 1000 * 10,
    });
  },

  useGetWorkshopThumbnail(workshopId: string) {
    return useQuery({
      queryKey: ['workshop', workshopId, 'thumbnail'],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Workshops.getWorkshopThumbnail(workshopId, { signal });
        return res.ok ? res.value : '';
      },
      refetchOnWindowFocus: false,
      networkMode: 'offlineFirst',
    });
  },

  useGetActiveModelVersionId(workshopId: string, opts?: { enabled?: boolean; refetchInterval?: number }) {
    return useQuery({
      queryKey: ['workshops', workshopId, 'active-model-version-id'],
      queryFn: async () => Oasis.Workshops.getActiveModelVersionId(workshopId),
      networkMode: 'always',
      ...opts,
    });
  },
};
