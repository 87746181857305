import type { ComponentProps } from 'react';

export function MicOffIcon(props: ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="none" {...props}>
      <path
        fill="currentColor"
        d="M20.107 10.896A10.925 10.925 0 0 1 29.808 5c6.034 0 10.926 4.892 10.926 10.926v17.316L19.519 12.027l.588-1.131ZM39.27 39.983l-.995 1.219c-2 2.45-5.051 4.02-8.467 4.02-6.034 0-10.926-4.892-10.926-10.926V19.595L39.27 39.983Z"
      />
      <path
        fill="currentColor"
        d="M13.734 32.556c.961 0 1.74.779 1.74 1.74 0 7.917 6.418 14.334 14.334 14.334 4.256 0 8.077-1.853 10.705-4.801a1.74 1.74 0 0 1 2.599 2.316 17.78 17.78 0 0 1-11.563 5.882v3.492h14.333a1.74 1.74 0 0 1 0 3.481H13.734a1.74 1.74 0 1 1 0-3.481h14.334v-3.492c-9.022-.875-16.075-8.48-16.075-17.73 0-.962.78-1.741 1.741-1.741ZM45.882 32.556c.962 0 1.74.779 1.74 1.74 0 .582-.027 1.157-.082 1.725a1.74 1.74 0 1 1-3.465-.334c.044-.457.067-.92.067-1.39 0-.962.78-1.741 1.74-1.741Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.206 7.758a1.74 1.74 0 0 1 2.461 0l45.127 45.127a1.74 1.74 0 0 1-2.461 2.462L8.206 10.219a1.74 1.74 0 0 1 0-2.461Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
