import type { ComponentProps } from 'react';

export function MicIcon(props: ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
      <path fill="currentColor" d="M7.833 5.972a4.097 4.097 0 0 1 8.195 0v6.89a4.097 4.097 0 0 1-8.195 0v-6.89Z" />
      <path
        fill="currentColor"
        d="M5.903 12.208c.36 0 .652.293.652.653a5.375 5.375 0 0 0 10.75 0 .653.653 0 1 1 1.306 0 6.681 6.681 0 0 1-6.028 6.65v1.309h5.375a.653.653 0 0 1 0 1.305H5.903a.653.653 0 1 1 0-1.305h5.375v-1.31a6.681 6.681 0 0 1-6.028-6.649c0-.36.292-.653.653-.653Z"
      />
    </svg>
  );
}
