import { DesktopIcon, MoreVerticalIcon, PencilIcon, TrashCanIcon, XIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import Tabs, { Tab } from '@adsk/alloy-react-tabs';
import { Oasis } from '@oasis/sdk';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useWorkshopPermissions } from '~/features/workshops/hooks/use-workshop-permissions';
import { Button } from '~/shared/components/base/button';
import { Confirmation } from '~/shared/components/base/confirmation';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { Popover } from '~/shared/components/base/popover';
import { InlineEditText } from '~/shared/components/forms/inline-edit-text';
import { HeadsetIcon } from '~/shared/components/icons/headset-icon';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Mutations } from '~/shared/hooks/mutations';
import { Queries } from '~/shared/hooks/queries';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';
import { useOpenModal } from '~/shared/hooks/use-modal';
import { OasisError } from '~/shared/utils/oasis-error';
import { WorkshopDetailsTabs, useWorkshopDetails } from '../../hooks/use-workshop-details';
import { WorkshopDetailsTab } from './components/workshop-details-tab';
import { WorkshopPermissionsTab } from './components/workshop-permissions-tab';
import { WorkshopSettingsTab } from './components/workshop-settings-tab';

/**
 * Notes:
 * - Only users with `canManage` permissions are allowed to see the permission panel.
 */
export function WorkshopShowPage() {
  const { projectId } = useProjectContext();
  const $session = Oasis.Session.useStore();
  const $env = Oasis.Env.useStore();
  const openModal = useOpenModal();
  const { workshopId } = useParams<{ workshopId: string }>();

  if (!workshopId) {
    throw new Error('<WorkshopShowPage /> Missing required `workshopId` param.');
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const [enableDesktop, showClearAnnotations] = useFeatureFlags([
    '5465-enter-on-desktop',
    'OASIS-6824-clear-all-annotations',
  ]);

  const { activeTab, showPanel, hidePanel } = useWorkshopDetails();
  const permissions = useWorkshopPermissions(workshopId);

  const [isEditing, setIsEditing] = useState(false);

  Queries.Workshops.useGetWorkshopSettings(workshopId); // prefetch
  const workshop = Queries.Workshops.useFindWorkshopById({ workshopId });
  const updateWorkshop = Mutations.Workshops.useUpdateWorkshop();
  const deleteWorkshop = Mutations.Workshops.useDeleteWorkshop();
  const openWorkshop = Mutations.Workshops.useOpenWorkshopOnDevice();

  function changeTab(tab: WorkshopDetailsTabs) {
    if (workshopId) {
      showPanel(workshopId, tab);
    }
  }

  function handleDelete() {
    Confirmation.show({
      title: 'Delete workshop?',
      description: "This action cannot be undone. Deleting this workshop won't delete any of your files or issues.",
      confirmLabel: 'Delete',
      confirm: () => {
        if (workshopId) {
          deleteWorkshop.mutateAsync(
            { id: workshopId, name: workshop.data?.name },
            {
              onSuccess() {
                searchParams.delete('workshopId');
                setSearchParams(searchParams);
              },
            }
          );
        }
      },
    });
  }

  if (!workshopId) {
    return null;
  }

  if (workshop.isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <ProgressRing size="medium" />
      </div>
    );
  }

  if (workshop.data) {
    return (
      <div className="flex flex-col h-full overflow-hidden">
        <header className="flex items-center p-4">
          <h2 className="text-heading-2 mr-20 flex-1 overflow-hidden">
            <InlineEditText
              key={workshop.data.id}
              initialValue={workshop.data.name}
              onSubmit={name => updateWorkshop.mutate({ workshopId, attrs: { name } })}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              disabled={!permissions.canEdit || $session.license.type === 'FREE_VIEWER'}
            />
          </h2>
          <div className="ml-auto flex items-center space-x-3 pr-2">
            {permissions.canView && (
              <Popover>
                <Popover.Trigger className="opacity-50 hover:opacity-100">
                  <MoreVerticalIcon />
                </Popover.Trigger>
                <Popover.Content
                  hideWhenDetached
                  side="bottom"
                  align="end"
                  sideOffset={-8}
                  alignOffset={8}
                  className="z-20"
                >
                  {$env.isVr ? (
                    <Popover.Button
                      icon={<HeadsetIcon className="w-6 h-6" />}
                      disabled={workshop.isLoading}
                      onClick={() => {
                        openWorkshop.mutate({ device: Oasis.Env.store.vrTargetDevice, id: workshop.data.id });
                      }}
                    >
                      Enter
                    </Popover.Button>
                  ) : (
                    <Popover.Button
                      icon={<HeadsetIcon className="w-6 h-6" />}
                      disabled={workshop.isLoading}
                      onClick={() => {
                        openModal('open-workshop-headset', {
                          workshopId: workshop.data.id,
                          workshopName: workshop.data.name,
                        });
                      }}
                    >
                      Enter on headset
                    </Popover.Button>
                  )}

                  {!$env.isVr && enableDesktop && (
                    <Popover.Button
                      icon={<DesktopIcon size={24} />}
                      disabled={workshop.isLoading}
                      onClick={async () => {
                        openModal('open-workshop-desktop', {
                          workshopId: workshop.data.id,
                          workshopName: workshop.data.name,
                        });
                      }}
                    >
                      Enter on desktop
                    </Popover.Button>
                  )}

                  {permissions.canEdit && (
                    <>
                      <hr className="border-t border-charcoal-100 my-1" />
                      <Popover.Button icon={<PencilIcon size={24} />} onClick={() => setIsEditing(true)}>
                        Rename
                      </Popover.Button>
                      <Popover.Button
                        destructive
                        onClick={handleDelete}
                        isLoading={deleteWorkshop.isPending}
                        icon={<TrashCanIcon />}
                      >
                        Delete workshop
                      </Popover.Button>
                    </>
                  )}
                </Popover.Content>
              </Popover>
            )}

            <button
              className="ml-auto flex items-center justify-center appearance-none opacity-50 hover:opacity-100"
              onClick={hidePanel}
            >
              <XIcon />
            </button>
          </div>
        </header>

        <Tabs
          active={!permissions.canManage ? 'details' : activeTab}
          className="!flex flex-col flex-1 overflow-hidden"
          onChange={tab => {
            changeTab(tab as WorkshopDetailsTabs);
          }}
        >
          <Tab
            label="Details"
            tab="details"
            labelProps={{ className: showClearAnnotations ? 'w-1/3' : 'w-1/2' }}
            className="!flex flex-col flex-1 overflow-auto"
          >
            <WorkshopDetailsTab projectId={projectId} workshop={workshop.data} />
          </Tab>

          <Tab
            label="Permissions"
            tab="permissions"
            labelProps={{ className: showClearAnnotations ? 'w-1/3' : 'w-1/2' }}
            className="flex-1 overflow-auto"
            disabled={!permissions.canManage}
          >
            <WorkshopPermissionsTab projectId={projectId} workshopId={workshopId} />
          </Tab>

          {showClearAnnotations && (
            <Tab
              label="Settings"
              tab="settings"
              labelProps={{ className: showClearAnnotations ? 'w-1/3' : 'w-1/2' }}
              className="flex-1 overflow-auto"
              disabled={!permissions.canManage}
            >
              <WorkshopSettingsTab projectId={projectId} workshopId={workshopId} workshopName={workshop.data.name} />
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }

  if (workshop.error instanceof OasisError) {
    const title = (() => {
      switch (workshop.error.code) {
        case 'NOT_FOUND':
          return "Couldn't find a workshop with that ID.";
        case 'FORBIDDEN':
          return "You don't have permission to view this workshop.";
      }
    })();

    return (
      <>
        <button className="absolute top-2 right-2 p-2 appearance-none opacity-50 hover:opacity-100" onClick={hidePanel}>
          <XIcon />
        </button>

        <OasisErrorState code={workshop.error.code} title={title}>
          <Button onClick={hidePanel}>Close</Button>
        </OasisErrorState>
      </>
    );
  }

  return <OasisErrorState />;
}
