import { XIcon } from '@adsk/alloy-react-icon';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { MicIcon } from './icons/mic';
import { MicOffIcon } from './icons/mic-off';
import { VoiceSignalIcon } from './icons/voice-signal';
import { Volume2Icon } from './icons/volume-2';
import { VolumeXIcon } from './icons/volume-x';

export function VoiceManager() {
  const $voice = Oasis.Voice.useStore();
  const [micMuted, setMicMuted] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);

  useEffect(() => {
    Oasis.Voice.init();
  }, []);

  function handleMuteMic() {
    setMicMuted(!micMuted);
  }

  function handleMuteAudio() {
    setAudioMuted(!audioMuted);
  }

  if (!$voice.active) {
    return null;
  }

  return (
    <aside className="flex items-center fixed bottom-4 left-1/2 -translate-x-1/2 py-3 pl-5 pr-3 rounded shadow-high bg-white z-50">
      <div>
        <p
          className={clsx(
            'text-green-500 text-heading-4 flex items-center',
            $voice.status === 'CONNECTED' && 'text-green-500',
            $voice.status === 'DISCONNECTED' && 'text-red-500',
            !['CONNECTED', 'DISCONNECTED'].includes($voice.status) && 'text-yellorange-500'
          )}
        >
          <VoiceSignalIcon className={'mr-2 w-5'} />
          Voice {$voice.status.toLowerCase()}
        </p>

        {$voice.workshop?.name && <p className="text-label-sm mt-1 text-charcoal-600">{$voice.workshop.name}</p>}
      </div>

      <div className="ml-5 flex items-center">
        <button
          className="w-10 h-10 flex"
          onClick={handleMuteMic}
          title={micMuted ? 'Unmute microphone' : 'Mute microphone'}
        >
          {micMuted ? (
            <MicOffIcon className="w-5 m-auto opacity-70 hover:opacity-100" />
          ) : (
            <MicIcon className="w-5 m-auto opacity-70 hover:opacity-100" />
          )}
        </button>

        <button className="w-10 h-10 flex" onClick={handleMuteAudio} title={audioMuted ? 'Unmute audio' : 'Mute audio'}>
          {audioMuted ? (
            <VolumeXIcon className="w-5 m-auto opacity-70 hover:opacity-100" />
          ) : (
            <Volume2Icon className="w-5 m-auto opacity-70 hover:opacity-100" />
          )}
        </button>

        <button className="w-10 h-10 flex" onClick={Oasis.Voice.disconnect} title="Disconnect">
          <XIcon className="w-5 m-auto opacity-70 hover:opacity-100" />
        </button>
      </div>
    </aside>
  );
}
