import { HTTPError, type BeforeRetryState } from 'ky';
import { Oasis } from '../../oasis';
import { TokenManager } from '../../services/token-manager/token-manager.service';
import { Storage } from '../storage/storage.provider';

/**
 * @name createAuthHook
 * Set the authorization header on the request before each one.
 */
export function createSetHeadersHook() {
  return async (request: Request) => {
    if (request.headers.get('Authorization') === null) {
      const accessToken = await TokenManager.getAccessToken();

      if (accessToken.ok) {
        request.headers.set('Authorization', `Bearer ${accessToken.value}`);
      }
    }

    if (request.headers.get('Region') === null) {
      const region = Storage.get('region') ?? 'US';
      request.headers.set('Region', region);
    }
  };
}

/**
 * @name createRefreshHook
 * Ky hook to refresh the session when a request fails, before retrying it.
 */
export function createRefreshHook() {
  return async ({ request, error }: BeforeRetryState) => {
    if (Oasis.Env.store.online && error instanceof HTTPError && !request.url.includes('session/refresh')) {
      const token = await Oasis.TokenManager.refresh();

      if (token.ok) {
        request.headers.set('Authorization', `Bearer ${token.value.oxygenToken}`);
      }
    }
  };
}
