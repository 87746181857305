import { Viewer } from '@oasis/fluid-interop/concurrentCollaborationExtension/globals';
import React from 'react';
import { createRoot, Root } from 'react-dom/client';

const roots = new Map<Element, Root>();
export function addCustomControl(viewer: Viewer, controlId: string, toolTip: string, icon: React.ReactNode, onClick: () => void) {
    const button = new Autodesk.Viewing.UI.Button(controlId);
    button.onClick = onClick;
    button.setToolTip(toolTip);
    button.addClass(controlId);

    const subToolbar = new Autodesk.Viewing.UI.ControlGroup('workshop-xr-viewer-controls');
    subToolbar.addControl(button);

    viewer.toolbar.addControl(subToolbar);

    const buttonElement = document.getElementById(controlId)!.getElementsByClassName('adsk-button-icon')[0]!;

    if (roots.has(buttonElement)) {
        const root = roots.get(buttonElement)!;
        root.render(icon);
    } else {
        const root = createRoot(buttonElement);
        roots.set(buttonElement, root);
        root.render(icon);
    }
}
