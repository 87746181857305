import { Oasis } from '@oasis/sdk';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSupportedFileTypes } from '~/features/workshops/hooks/use-supported-file-types';
import { Queries } from '~/shared/hooks/queries';
import { SearchInput } from '../forms/search-input';
import { FolderSearchList } from './folder-search-list';
import { FolderTree } from './folder-tree';
import type { FolderTreeSelectionItem } from './folder-tree/types';

interface Props {
  projectId: string;
  selectedItem?: FolderTreeSelectionItem;
  onChange(item: FolderTreeSelectionItem): void;
}

export function ModelSelectionTable({ onChange, ...props }: Props) {
  const $env = Oasis.Env.useStore();

  const supportedFileTypes = useSupportedFileTypes();
  const [query, setQuery] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const topFolders = Queries.Projects.useListTopFolders({ projectId: props.projectId });

  const selection = useMemo(() => {
    return props.selectedItem ? [props.selectedItem] : [];
  }, [props.selectedItem]);

  const handleFileSelect = useCallback(
    (item: FolderTreeSelectionItem) => onChange(item), //
    [onChange]
  );

  useEffect(() => {
    // Scroll the file into view after another file's 3d views list potentially collapses
    // and renders this new selected file out of the container's viewport.
    setTimeout(() => {
      const el = containerRef.current?.querySelector('[data-folder-tree-file-status="active"]');
      el?.scrollIntoView({ behavior: 'smooth' });
    }, 50);
  }, [props.selectedItem?.urn]);

  if (!topFolders.data?.rootFolderUrn) {
    return null;
  }

  return (
    <>
      <div>
        <SearchInput value={query} onTextChange={setQuery} />
      </div>

      <div
        ref={containerRef}
        className="border border-charcoal-400 mt-5 rounded min-w-full h-72 max-h-full overflow-auto"
      >
        {!query && (
          <FolderTree
            mode="FILE_REFERENCE_RADIO"
            selection={selection}
            onFileSelect={handleFileSelect}
            shareableOnly
            disableFolderSearchParam
            disableFolderPersistance
          />
        )}

        {query && (
          <FolderSearchList
            mode="FILE_REFERENCE_RADIO"
            projectId={props.projectId}
            folderUrns={[topFolders.data.rootFolderUrn]}
            query={query}
            fileTypes={supportedFileTypes.extensions}
            selection={selection}
            onFileSelect={handleFileSelect}
          />
        )}
      </div>
    </>
  );
}
