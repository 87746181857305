import { WorkshopXRUser } from '@oasis/fluid-interop/connection/connectionManager';
import { Oasis } from '@oasis/sdk';
import { ArrayUtils } from '@oasis/utils';
import { useEffect } from 'react';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { useWorkshopState } from './use-workshop-state';

type AudienceProxy = {
  allUsers: WorkshopXRUser[];
  viewingUsers: WorkshopXRUser[];
  currentUser: WorkshopXRUser | undefined;
  hostUser: WorkshopXRUser | undefined;
};

export const audienceProxy = proxy<AudienceProxy>({
  allUsers: [],
  viewingUsers: [],
  currentUser: undefined,
  hostUser: undefined,
});

export function useWorkshopAudience(workshopId?: string, filterSelf = true) {
  const $session = Oasis.Session.useStore();
  const { workshopConnection, hostUserId } = useWorkshopState({ workshopId });

  useEffect(() => {
    if (!workshopConnection) {
      return;
    }

    const audience = workshopConnection.audience;
    const updateUsers = () => {
      const sessionUsers = workshopConnection.getSessionUsers();

      const nextViewingUsers: WorkshopXRUser[] = [];
      let nextCurrentUser: WorkshopXRUser | undefined;
      let nextHostUser: WorkshopXRUser | undefined;

      for (const user of sessionUsers) {
        if (!user.additionalDetails.device) {
          continue;
        }

        if (user.userId === $session.user?.id && user.additionalDetails.device === 'WEB') {
          nextCurrentUser = user;
          continue;
        }

        if (user.userId === hostUserId && user.additionalDetails.device === 'VR') {
          nextHostUser = user;
          continue;
        }

        nextViewingUsers.push(user);
      }

      audienceProxy.allUsers = (
        nextCurrentUser?.userId === nextHostUser?.userId
          ? [nextCurrentUser, ...nextViewingUsers]
          : [nextCurrentUser, nextHostUser, ...nextViewingUsers]
      ).filter(ArrayUtils.truthy);
      audienceProxy.viewingUsers = nextViewingUsers;
      audienceProxy.currentUser = nextCurrentUser;
      audienceProxy.hostUser = nextHostUser;
    };

    audience.on('memberAdded', updateUsers);
    audience.on('memberRemoved', updateUsers);
    audience.on('membersChanged', updateUsers);

    updateUsers();

    return () => {
      audience.off('memberAdded', updateUsers);
      audience.off('memberRemoved', updateUsers);
      audience.off('membersChanged', updateUsers);
    };
  }, [workshopConnection, filterSelf, hostUserId, $session.user?.id]);

  return useProxy(audienceProxy);
}
