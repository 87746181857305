import { Oasis, type OasisResponses } from '@oasis/sdk';
import { NetworkMode, useQuery } from '@tanstack/react-query';
import { useProjectContext } from '~/shared/contexts/project-context';
import { OasisError } from '~/shared/utils/oasis-error';
import { OasisParams } from '~/types';

export type ProjectWithRegion = OasisResponses['Projects']['ListProjects']['results'][0] & { region: string };

export const projectsQueries = {
  useListAllProjects(
    params?: OasisParams<'Projects', 'listProjects'> & {
      opts?: {
        networkMode?: NetworkMode;
        enabled?: boolean;
        staleTime?: number;
        refetchOnWindowFocus?: boolean;
        refetchInterval?: number;
      };
    }
  ) {
    const $env = Oasis.Env.useStore();

    return useQuery({
      queryKey: ['projects', { isDevMode: $env.isDevMode }],
      queryFn: async () => {
        const responses = new Array<OasisResponses['Projects']['ListProjects']>();

        for (const region of ['US', 'EMEA', 'AUS']) {
          const res = await Oasis.Projects.listProjects({ ...params, region });

          if (!res.ok) {
            continue;
          }

          responses.push(res.value);

          // if (!allProjects.length) {
          //   // While debugging an unlikely flow where users sign up before going through the trial
          //   // flow we were seeing 404s from this response. Instead of throwing an error, we want to
          //   // just return "No projects"
          //   if (res.error.code === 'NOT_FOUND') {
          //     return {
          //       pagination: { limit: 0, offset: 0, totalResults: 0 },
          //       results: [],
          //     };
          //   }

          //   throw new OasisError({ code: 'NOT_FOUND' }, '[Projects.useListProjects]');
          // }

          if (res.value.pagination.totalResults > res.value.pagination.limit) {
            const totalPages = Math.ceil(res.value.pagination.totalResults / res.value.pagination.limit);
            const pageNumbers = Array.from(Array(totalPages).keys()).slice(1);

            for (const page of pageNumbers) {
              const moreProjects = await Oasis.Projects.listProjects({
                pagination: {
                  limit: res.value.pagination.limit,
                  offset: page * (res.value.pagination.limit ?? 0),
                },
              });

              if (moreProjects.ok) {
                responses.push(moreProjects.value);
              }
            }
          }
        }

        const allProjects: ProjectWithRegion[] = [];

        for (const { region, results } of responses) {
          allProjects.push(...results.map(project => ({ ...project, region })));
        }

        return allProjects;
      },
      staleTime: 10_000,
      refetchOnWindowFocus: false,
      ...params?.opts,
    });
  },

  useListTopFolders(params: { projectId: string }) {
    const { hubId } = useProjectContext();

    return useQuery({
      queryKey: ['projects', params.projectId, 'top-folders'],
      queryFn: async () => {
        const res = await Oasis.Projects.listTopFolders({
          hubId,
          projectId: params.projectId,
        });

        if (!res.ok) {
          throw new OasisError(res.error, '[Projects.useFindProjectById]');
        }

        return res.value;
      },
      networkMode: 'offlineFirst',
      refetchOnWindowFocus: false,
    });
  },

  useListAdminUsers(params: OasisParams<'Projects', 'listAdminUsers'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'admins', params],
      queryFn: async () => {
        const res = await Oasis.Projects.listAdminUsers(params);
        if (!res.ok) throw new OasisError(res.error, '[Projects.useListAdminUsers]');
        return res.value;
      },
    });
  },

  useListProjectUsers(params: OasisParams<'Projects', 'listProjectUsers'> & { opts?: { enabled?: boolean } }) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'users', params],
      queryFn: async () => {
        const res = await Oasis.Projects.listProjectUsers(params);
        if (!res.ok) throw new OasisError(res.error, '[Projects.useListProjectUsers]');
        return res.value;
      },
      enabled: params.opts?.enabled,
    });
  },

  useListLocations(params: OasisParams<'Projects', 'listLocations'> & { opts?: { enabled?: boolean } }) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'locations', params],
      queryFn: async () => {
        const res = await Oasis.Projects.listLocations(params);
        if (!res.ok) throw new OasisError(res.error, '[Projects.useListLocations]');
        return res.value;
      },
      enabled: params.opts?.enabled,
    });
  },

  useListRoles(params: OasisParams<'Projects', 'listRoles'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'roles', params],
      queryFn: async () => {
        const res = await Oasis.Projects.listRoles(params);
        if (!res.ok) throw new OasisError(res.error, '[Projects.useListRoles]');
        return res.value;
      },
    });
  },

  useListCompanies(params: OasisParams<'Projects', 'listCompanies'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'companies', params],
      queryFn: async () => {
        const res = await Oasis.Projects.listCompanies(params);
        if (!res.ok) throw new OasisError(res.error, '[Projects.useListCompanies]');
        return res.value;
      },
    });
  },
};
