import { ConnectionManager } from '@oasis/fluid-interop';
import { onCameraChanged, updateFollow } from '@oasis/fluid-interop/concurrentCollaborationExtension/main';
import { Oasis } from '@oasis/sdk';
import throttle from 'lodash/throttle';
import { useEffect, useRef } from 'react';
import { useFeatureFlags } from './use-feature-flags';
import { useWorkshopState } from './use-workshop-state';

type signalInput = {
  content: {
    playerPosition: {
      X: number;
      Y: number;
      Z: number;
    };
    playerForwardVector: {
      X: number;
      Y: number;
      Z: number;
    };
  };
};

const getCameraInfo = ({
  position,
  forward,
  up = { X: 0, Y: 0, Z: 1 },
}: {
  position: { X: number; Y: number; Z: number };
  forward: { X: number; Y: number; Z: number };
  up?: { X: number; Y: number; Z: number };
}) => {
  return {
    position: [position.X, position.Y, position.Z],
    target: [position.X + forward.X, position.Y + forward.Y, position.Z + forward.Z],
    up: [up.X, up.Y, up.Z],
  };
};

export const useUnrealLmvCamerasSync = ({
  viewer,
  fluidConnection,
  isCollaborativeWebViewer = false,
}: {
  viewer: Autodesk.Viewing.GuiViewer3D | undefined;
  fluidConnection: ConnectionManager | undefined;
  isCollaborativeWebViewer?: boolean;
}) => {
  const [isCameraSync] = useFeatureFlags(['241015-7221-unreal-lmv-cameras-sync']);
  const $debug = Oasis.Debug.useStore();
  const lastIsCameraSyncMode = useRef(isCameraSync);
  const { activeWorkshopId } = useWorkshopState();

  const extension = viewer?.getExtension('Autodesk.ConcurrentCollaboration');
  useEffect(() => {
    if (isCameraSync !== lastIsCameraSyncMode.current) {
      // reload
      window.location.reload();
    }

    lastIsCameraSyncMode.current = isCameraSync;
  }, [isCameraSync]);

  useEffect(() => {
    if ((!isCameraSync && !isCollaborativeWebViewer) || fluidConnection === undefined) {
      return;
    }

    if (viewer && activeWorkshopId) {
      const topic = `camera/ue`;

      const onCameraSignalsFollow = throttle((clientId: string, _local: boolean, payload: signalInput) => {
        if (!payload.content || !$debug.lmvCameraSync) {
          return;
        }
        const { content: cameraInfo } = payload;
        const { playerPosition, playerForwardVector } = cameraInfo;

        if (!playerPosition || !playerForwardVector) {
          return;
        }

        if (!viewer.navigation.getCamera().isPerspective) {
          viewer.navigation.toPerspective();
        }

        updateFollow({
          cameraInfo: getCameraInfo({ position: playerPosition, forward: playerForwardVector }),
          clientId,
          extension,
          viewer,
        });
      }, 60);

      const onCameraSignalUpdateUser = throttle((clientId, local, payload) => {
        const extension = viewer.getExtension('Autodesk.ConcurrentCollaboration');
        if (!payload.content || !extension || !isCollaborativeWebViewer) {
          return;
        }
        const { content: cameraInfo, user } = payload;
        const { playerPosition, playerForwardVector } = cameraInfo;

        if (!playerPosition || !playerForwardVector) {
          return;
        }

        if (!viewer.navigation.getCamera().isPerspective) {
          viewer.navigation.toPerspective();
        }

        const { X, Y, Z } = playerPosition;
        const { X: FX, Y: FY, Z: FZ } = playerForwardVector;

        updateFollow({
          cameraInfo: getCameraInfo({ position: playerPosition, forward: playerForwardVector }),
          clientId,
          extension,
          viewer,
        });

        // extract world rotation
        onCameraChanged({
          clientId,
          extension,
          connectionManager: fluidConnection,
          cursoProps: {
            userImage: '',
            color: 0xffff00,
            name: '',
            position: { x: X, y: Y, z: Z },
            forward: { x: FX, y: FY, z: FZ },
          },

          message: {
            cameraInfo: {
              position: [X, Y, Z],
              direction: [FX, FY, FZ],
              rotation: [0, 0, 0],
              forward: [FX, FY, FZ],
              up: [0, 0, 0],
              target: [],
            },
            user: {
              ...user,
            },
            sheetId: '',
          },
        });
      }, 60);

      const listener = isCollaborativeWebViewer ? onCameraSignalUpdateUser : onCameraSignalsFollow;

      fluidConnection.onSignal(topic, listener);
      return () => {
        fluidConnection.offSignal(topic, listener);
      };
    }
  }, [
    viewer,
    fluidConnection,
    isCameraSync,
    $debug.lmvCameraSync,
    activeWorkshopId,
    isCollaborativeWebViewer,
    extension,
  ]);
};
