import throttle from 'lodash/throttle';
import { WorkshopXRUser } from '../connection/connectionManager';
import { ConnectionManager } from '../main';
import { CLIENT_CONNECTED, GET_CLIENT_INFO } from './constants';
import { Extension, Viewer } from './globals';
import { CursorProperties, LaserPointerHit } from './rendering/cursor';
import { CursorsManager } from './rendering/cursorManager';
import { setupViewerState } from './state';

const SHOW_SELF = false;
export interface CameraInfo {
  position: ReadonlyArray<number>;
  direction: ReadonlyArray<number>;
  rotation: ReadonlyArray<number>;
  forward: ReadonlyArray<number>;
  target: ReadonlyArray<number>;
  up: ReadonlyArray<number>;
}
interface Message {
  cameraInfo: CameraInfo;
  user: WorkshopXRUser;
  hit?: {
    point: ReadonlyArray<number>;
    keep: boolean;
    distance: number;
  };
  sheetId: string;
  disposedConnectionId?: string;
}

function getCameraInfo(viewer: Viewer): CameraInfo {
  const camera = viewer.getCamera();
  const cameraInfo: CameraInfo = {
    direction: camera.getWorldDirection().toArray(),
    position: camera.getWorldPosition().toArray(),
    rotation: camera.getWorldRotation().toArray(),
    target: camera.target.toArray(),
    up: camera.up.toArray(),
    forward: [],
  };
  return cameraInfo;
}

const invalidateView = throttle((viewer: Viewer, needsRender = false) => {
  requestAnimationFrame(() => {
    if (!viewer.impl) return;
    viewer.impl.invalidate(/* needsClear */ true, /* needsRender */ needsRender, /* overlayDirty */ true);
  });
}, 60);

export const updateFollow = ({
  extension,
  viewer,
  cameraInfo,
  clientId,
  followAnyway = false,
}: {
  cameraInfo: {
    position: ReadonlyArray<number>;
    target: ReadonlyArray<number>;
    up: ReadonlyArray<number>;
  };
  clientId: string;
  followAnyway?: boolean;
  extension: Extension;
  viewer: Viewer;
}) => {
  const user = extension.connectionManager.getMembers().get(clientId);
  if (followAnyway || clientId === extension._followedClientId || (user && user.userId === extension._followedUserId)) {
    const [px, py, pz] = cameraInfo.position;
    const [tx, ty, tz] = cameraInfo.target;
    const [ux, uy, uz] = cameraInfo.up;

    viewer.navigation.setView(new THREE.Vector3(px, py, pz), new THREE.Vector3(tx, ty, tz));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(ux, uy, uz));
  }
};

export function onCameraChanged({
  extension,
  connectionManager,
  message,
  clientId,
  cursoProps,
}: {
  extension: Extension;
  connectionManager: ConnectionManager;
  message: Message;
  clientId: string;
  cursoProps: CursorProperties;
}) {
  const { cursorsManager, viewer, sheetId } = extension;

  const myself = connectionManager.getMyself();
  const user = connectionManager.getSessionUser(clientId);
  if (!user) {
    console.warn('User not found', message.user?.userId);
    return;
  }

  const { userName, userImage, color, userLastName } = user.additionalDetails;
  const userId = user.userId;
  /*
  * This is a workaround to show the user image in the cursor.
  * For local build we need to replace the autodesk profile url with the local profile url to avoid CORS issues.
  * The server should return higher resolution images for the cursor to look better. But for now, we 
  * are re-constructing the url to get the higher resolution image.
  */
  const AUTODESK_PROFILE_URL = 'https://images.profile.autodesk.com';
  const LOCAL_PROFILE_URL = '/profile/images';

  let url = userImage;
  const isAutodeskProfile = url && url.startsWith(AUTODESK_PROFILE_URL);
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname.startsWith('local');
  if (url && isAutodeskProfile && isLocalhost) {
    url = url.replace(AUTODESK_PROFILE_URL, LOCAL_PROFILE_URL);
    url = url.replace('x120', 'x360');
  }

  const name = `${userName?.trim() || 'anonymous'} ${userLastName?.trim().toUpperCase()[0] || ''}`;
  const remoteClientId = user.clientId;

  if (user && myself && (remoteClientId !== myself.clientId || SHOW_SELF)) {
    cursorsManager.add(
      clientId,
      {
        userImage: url,
        color,
        name,
        userId,
      },
      sheetId
    );
    cursorsManager.update(clientId, cursoProps, viewer.getCamera());
    invalidateView(viewer);
  } else {
    cursorsManager.remove(clientId);
    invalidateView(viewer);
  }
}

function getThrottledSignalSubmit(
  extension: Extension,
  connectionManager: ConnectionManager
): (hit?: LaserPointerHit) => void {
  return throttle((hit?: LaserPointerHit) => {
    const {
      viewer,
    }: {
      viewer: Viewer;
    } = extension;
    const myself = connectionManager.getMyself();
    const message: Message = {
      cameraInfo: getCameraInfo(viewer),
      sheetId: 'default',
      user: myself,
    };
    if (hit && hit.keep !== undefined) {
      message.hit = hit;
    }
    connectionManager.submitSignal(Autodesk.Viewing.CAMERA_CHANGE_EVENT, message);
  }, 50);
}

const setupCursorsSync = (extension: Extension, connectionManager: ConnectionManager) => {
  const {
    viewer,
    cursorsManager,
  }: {
    viewer: Viewer;
    cursorsManager: CursorsManager;
  } = extension;

  const throttledSignalSubmit = getThrottledSignalSubmit(extension, connectionManager);

  viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, throttledSignalSubmit);
  viewer.addEventListener(
    Autodesk.Viewing.CAMERA_CHANGE_EVENT,
    throttle(() => {
      if (!viewer || !viewer.impl) return;
      cursorsManager.cursors.forEach(cursor => cursor.lookAt(viewer.getCamera()));
      // needsRender = true is needed in cases when the scene is re-rendered after showing/hiding viewer panels.
      invalidateView(viewer, true);
    }, 100)
  );

  let isClickEvent = false;
  let isOurEvent = false;
  let lastPosition: { x: number; y: number };

  const mouseEventListener = (event: MouseEvent) => {
    const bbox = (event.target as HTMLElement).getBoundingClientRect();
    lastPosition = {
      x: event.clientX - bbox.left,
      y: event.clientY - bbox.top,
    };
    if (!event.altKey) {
      return;
    }
    isOurEvent = true;
    isClickEvent = event.type === 'click';
    if (isClickEvent) {
      viewer.canvas.removeEventListener('mousemove', mouseEventListener);
      setInterval(() => viewer.canvas.addEventListener('mousemove', mouseEventListener), 100);
    }
    const hit = viewer.hitTest(lastPosition.x, lastPosition.y, false);
    throttledSignalSubmit(
      hit
        ? { point: hit.intersectPoint.toArray(), keep: isClickEvent, distance: hit.distance }
        : { point: [], keep: false, distance: 0 }
    );
  };
  const onCameraChangedSignal = (clientId: string, local: boolean, message: Message) => {
    if (local) {
      // Local signals
    } else {
      const { cameraInfo, hit, user } = message;
      const { position, rotation } = cameraInfo;

      const userImage = connectionManager.getSessionUser(user.userId)?.additionalDetails?.userImage || '';

      updateFollow({ viewer, extension, cameraInfo, clientId });
      const cursoProps: CursorProperties = {
        position: {
          x: position[0] ?? 0,
          y: position[1] ?? 0,
          z: position[2] ?? 0,
        },
        rotation: {
          x: rotation[0] ?? 0,
          y: rotation[1] ?? 0,
          z: rotation[2] ?? 0,
        },
        hit,
        userImage,
      };

      onCameraChanged({ extension, connectionManager, message, clientId, cursoProps });
    }
  };
  const onSignalClientConnected = (clientId: string, local: boolean, message?: Message) => {
    if (local) {
      // request camera info from connected peers
      connectionManager.submitSignal(GET_CLIENT_INFO);
      getThrottledSignalSubmit(extension, connectionManager)();
    } else if (message) {
      const { disposedConnectionId } = message;
      if (disposedConnectionId) {
        cursorsManager.remove(disposedConnectionId);
        invalidateView(viewer);
      }
    }
  };

  viewer.canvas.addEventListener('mousemove', mouseEventListener);
  viewer.canvas.addEventListener('click', mouseEventListener);
  const keyUpListener = (event: KeyboardEvent) => {
    if (!isOurEvent) return;
    isOurEvent = false;
    // clear pointer
    if (event.key === 'Alt' && !isClickEvent) throttledSignalSubmit({ point: [], keep: false, distance: 0 });
  };

  extension.getWindow().addEventListener('keyup', keyUpListener);

  connectionManager.onSignal(
    Autodesk.Viewing.CAMERA_CHANGE_EVENT,
    onCameraChangedSignal
  );

  connectionManager.onSignal(CLIENT_CONNECTED, onSignalClientConnected);

  connectionManager.onSignal(GET_CLIENT_INFO, () => throttledSignalSubmit());

  const onMemberRemoved = (clientId: string) => {
    cursorsManager.remove(clientId);
    invalidateView(viewer);
  };

  connectionManager.audience.on('memberRemoved', onMemberRemoved);

  return () => {
    connectionManager.offSignal(Autodesk.Viewing.CAMERA_CHANGE_EVENT, onCameraChangedSignal);
    connectionManager.offSignal(CLIENT_CONNECTED, onSignalClientConnected);
    viewer.canvas.removeEventListener('mousemove', mouseEventListener);
    viewer.canvas.removeEventListener('click', mouseEventListener);
    extension.getWindow().removeEventListener('keyup', keyUpListener);
    connectionManager.audience.off('memberRemoved', onMemberRemoved);
  };
};

export const syncAudienceWithExtension = ({
  connectionManager,
  extension,
}: {
  connectionManager: ConnectionManager;
  extension: any;
}) => {
  const audience = connectionManager.audience;
  let isConnectionPending = audience.getMyself() === undefined;
  extension.connectionManager = connectionManager;
  const onConnectedAudience = () => {
    const myself = audience.getMyself();
    // Connection transitions can result in short timing windows where `getMyself` returns `undefined`.
    // This is because the current client connection will not have been added to the audience yet,
    // so a matching connection ID cannot be found. Similarly, offline scenarios may produce the same behavior.
    if (myself === undefined) return;
    if (isConnectionPending) {
      audience.off('membersChanged', onConnectedAudience);
      isConnectionPending = false;
    }
    audience.getMembers().forEach(member => {
      extension.addUser(member, false);
    });
    extension.updateUsers();
    audience.on('memberAdded', (clientId, member) => {
      if (extension?.viewer?.impl) {
        extension.addUser(member);
      }
    });

    audience.on('memberRemoved', (clientId, member) => {
      if (extension?.viewer?.impl) {
        extension.removeUser(member);
      }
    });

    (globalThis as any).NOP_AUDIENCE = audience;
  };
  if (isConnectionPending) {
    audience.on('membersChanged', onConnectedAudience);
  } else {
    onConnectedAudience();
  }

  return () => {
    audience.off('membersChanged', onConnectedAudience);
    extension.connectionMnaager = null;
  };
};

export interface CameraInfo {
  position: ReadonlyArray<number>;
  direction: ReadonlyArray<number>;
  rotation: ReadonlyArray<number>;
  target: ReadonlyArray<number>;
  up: ReadonlyArray<number>;
}

export function setup(
  extension: Extension,
  connectionManager: ConnectionManager,
  modelUrn: string,
  isCollaborativeWebViewer: boolean
): { destroy: () => void } {
  const { viewer } = extension;

  const { destroy: destroyState } = setupViewerState(connectionManager, viewer, modelUrn);

  let curosrsSyncDestoryer: (() => void) | undefined = () => { };
  let audienceSyncDestoryer: (() => void) | undefined = () => { };
  if (isCollaborativeWebViewer) {
    // For web viewer, we enable additional features like cursors sync and audience sync.
    // We suppose currently these features only for browser basde workflows and not console in VR/Desktop
    curosrsSyncDestoryer = setupCursorsSync(extension, connectionManager);
    audienceSyncDestoryer = syncAudienceWithExtension({ connectionManager, extension });
  }

  return {
    destroy: () => {
      destroyState();
      curosrsSyncDestoryer();
      audienceSyncDestoryer();
    },
  };
}
