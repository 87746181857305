import { VR_SUPPORTED_FILES } from '@oasis/utils';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';

export function useSupportedFileTypes() {
  const enableIfc = useFeatureFlags(['241209-7730-ifc-support']);

  let label = 'RVT or NWD';
  const extensions = [...VR_SUPPORTED_FILES];

  if (enableIfc) {
    label = 'RVT, NWD or IFC';
    extensions.push('ifc');
  }

  return {
    label,
    extensions,
  };
}
