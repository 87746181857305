import { ComponentProps } from 'react';

type Props = ComponentProps<'svg'>;

export function ExitIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none" {...props}>
      <path
        fill="#fff"
        d="M2.645 5.368v12.947c0 .546.35 1.031.868 1.204l7.62 2.54a1.27 1.27 0 0 0 1.67-1.205V3.146a1.27 1.27 0 0 0-1.625-1.22L3.56 4.15a1.27 1.27 0 0 0-.914 1.219ZM14.073 21.134v-1.692h5.714v-2.329a.847.847 0 0 1 1.694 0v2.329c0 .934-.759 1.692-1.694 1.692h-5.714ZM14.073 3.146h5.714c.936 0 1.694.758 1.694 1.693v2.328a.847.847 0 1 1-1.694 0V4.839h-5.714V3.146ZM17.102 8.46a.8.8 0 0 1 1.13 0l3.222 3.22a.8.8 0 0 1 0 1.132l-3.221 3.22a.8.8 0 0 1-1.131-1.13l1.861-1.862-4.32.005c-.342 0-.62-.358-.62-.8 0-.441.276-.8.618-.8l4.311-.005-1.85-1.85a.8.8 0 0 1 0-1.13Z"
      />
    </svg>
  );
}
