import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AppHistory } from '~/shared/utils/app-history';

export type WorkshopDetailsTabs = 'details' | 'permissions' | 'settings';

export function useWorkshopDetails() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { projectId, workshopId } = useParams<{ projectId: string; workshopId: string }>();

  if (!projectId) {
    throw new Error('[useWorkshopDetails] Missing required `projectId` path param.');
  }

  const showPanel = useShowWorkshopPanel({ projectId });
  const activeTab = searchParams.get('tab') ?? (undefined as WorkshopDetailsTabs | undefined);

  return {
    isOpen: !!workshopId,
    workshopId,
    activeTab,
    showPanel,
    hidePanel() {
      searchParams.delete('tab');

      navigate({
        pathname: `/projects/${projectId}`,
        search: searchParams.toString(),
      });
    },
  };
}

export function useShowWorkshopPanel(params: { projectId: string }) {
  return (id: string, tab: WorkshopDetailsTabs, hideModal = true) => {
    AppHistory.searchParams.set('tab', tab);

    if (hideModal) {
      AppHistory.searchParams.delete('dialog');
    }

    AppHistory.navigate({
      pathname: `/projects/${params.projectId}/workshops/${id}`,
      search: AppHistory.searchParams.toString(),
    });
  };
}
