import Checkbox from '@adsk/alloy-react-checkbox';
import { RadioButton } from '@adsk/alloy-react-radio-button';
import { FileUtils } from '@oasis/utils';
import clsx from 'clsx';
import { Queries } from '~/shared/hooks/queries';
import { FileTypeIcon } from './file-type-icon';
import type { FolderTreeSelectionItem } from './folder-tree/types';

export type FolderSearchListMode = 'FILE_REFERENCE_RADIO' | 'FILE_REFERENCE_CHECKBOX';

interface Props {
  projectId: string;
  folderUrns: string[];
  query: string;
  mode?: FolderSearchListMode;
  fileTypes?: string[];
  selection?: FolderTreeSelectionItem[];
  onFileSelect?: (content: FolderTreeSelectionItem) => void;
}

export function FolderSearchList(props: Props) {
  const searchResults = Queries.Files.useSearchFiles({
    projectId: props.projectId,
    folderUrns: props.folderUrns,
    query: props.query,
    fileTypes: props.fileTypes,
    sort: [{ field: 'name', order: 'asc' }],
    opts: {
      enabled: Boolean(props.query && props.folderUrns.length),
    },
  });

  if (!searchResults.data) {
    return null;
  }

  return (
    <ul>
      {searchResults.data.documents.map((document, index) => {
        let path = document.parentFolder.name;
        const fileType = FileUtils.getFileType({
          type: 'items',
          name: document.name,
          fileType: document.includedVersion.fileType,
        });

        if (document.parentFolder.parents.length) {
          for (const parent of document.parentFolder.parents) {
            path = `${parent.name}/${path}`;
          }
        }

        const isChecked = props.selection?.some(item => item.type === 'model' && item.urn === document.urn);
        const disabled = !document.supportedInVr;

        return (
          <li key={document.urn}>
            <button
              className={clsx(
                'w-full flex items-center py-3 px-4',
                index > 0 && 'border-t border-charcoal-200',
                disabled && 'opacity-40'
              )}
              onClick={() => props.onFileSelect?.({ type: 'model', name: document.name, urn: document.urn })}
              disabled={disabled}
            >
              <div className="flex items-center justify-center w-6">
                {props.mode === 'FILE_REFERENCE_RADIO' && <RadioButton checked={isChecked} disabled={disabled} />}
                {props.mode === 'FILE_REFERENCE_CHECKBOX' && <Checkbox checked={isChecked} disabled={disabled} />}
              </div>

              <FileTypeIcon contentType="items" fileType={fileType} className="ml-2 mr-4" />

              <div className="text-left">
                <p className="text-body-md">{document.name}</p>
                <p className="text-body-sm text-charcoal-500">{path}</p>
              </div>
            </button>
          </li>
        );
      })}
    </ul>
  );
}
