import { Extension, Overlays } from '../globals';
import { loadFace } from './threejsfaces';

type vec = {
  x: number;
  y: number;
  z: number;
};

export type LaserPointerHit = {
  point: ReadonlyArray<number>;
  keep: boolean;
  distance: number;
};

export type CursorProperties = {
  position?: vec;
  rotation?: vec;
  forward?: vec;
  color?: number;
  name?: string;
  hit?: LaserPointerHit | undefined;
  userImage: string;
};


const DEFAULT_IMAGE = '/img/avatar_default.svg';
const unitToPixel = 256;
const pixelToUnit = 6 / unitToPixel;

const OUTER_RADIUS = pixelToUnit * 256 / 2;
const INNER_RADIUS = pixelToUnit * 200 / 2;

export class Cursor {
  private readonly username: THREE.Group;
  private readonly group: THREE.Group;
  private readonly cursor: THREE.Group;
  private laserPointerIsEnabled = true;
  private hitPointer?: THREE.Group;
  private sphereMaterial: THREE.MeshPhongMaterial;
  circleDefaultMaterial: THREE.MeshPhongMaterial;
  materialWithImage?: THREE.MeshBasicMaterial;
  circleMesh: THREE.Mesh;

  constructor(
    public readonly id: string,
    private readonly properties: CursorProperties = {
      color: 0xffff00,
      userImage: '',
    },
    private readonly sceneId: string,
    private readonly overlays: Overlays,
    private readonly extension: Extension,
    public readonly sheetId: string
  ) {
    loadFace();

    const { color } = properties;

    this.sphereMaterial = new THREE.MeshPhongMaterial({ color: 0x404040, side: THREE.DoubleSide });
    this.circleDefaultMaterial = new THREE.MeshPhongMaterial({ color: color, side: THREE.DoubleSide });

    // Define the rounded rectangle
    const width = pixelToUnit * 256;
    const height = pixelToUnit * 62;
    const radius = pixelToUnit * 30;

    // @ts-ignore
    const nameGeometry = new THREE.TextGeometry(properties.name || '', {
      font: 'artifakt element',
      style: 'normal',
      weight: 'normal',
      size: 27 * pixelToUnit,
      height: 1 * pixelToUnit,
      depth: 10,
      curveSegments: 20,

    });
    nameGeometry.center();

    const nameMaterial = new THREE.MeshBasicMaterial({
      color: 0xF5F5F5,
    });

    const nameMesh = new THREE.Mesh(nameGeometry, nameMaterial);

    // add background elipse for the name tag
    const nameTag = new THREE.Group();
    nameMesh.renderOrder = 1;
    nameTag.add(nameMesh);

    const nameTagMaterial = new THREE.MeshBasicMaterial({
      color: 0x000000,
      opacity: 0.5,
    });

    // move name mesh slightly 
    nameMesh.position.z = 0.01;
    nameMesh.geometry.computeBoundingBox();
    nameMesh.geometry.computeBoundingSphere();

    // Create a shape
    const shape = new THREE.Shape();

    shape.moveTo(-width / 2 + radius, -height / 2);
    shape.lineTo(width / 2 - radius, -height / 2);
    // @ts-ignore
    shape.quadraticCurveTo(width / 2, -height / 2, width / 2, -height / 2 + radius);
    shape.lineTo(width / 2, height / 2 - radius);
    // @ts-ignore
    shape.quadraticCurveTo(width / 2, height / 2, width / 2 - radius, height / 2);
    shape.lineTo(-width / 2 + radius, height / 2);
    // @ts-ignore
    shape.quadraticCurveTo(-width / 2, height / 2, -width / 2, height / 2 - radius);
    shape.lineTo(-width / 2, -height / 2 + radius);
    // @ts-ignore
    shape.quadraticCurveTo(-width / 2, -height / 2, -width / 2 + radius, -height / 2);

    // @ts-ignore
    const nameTagGeometry = new THREE.ShapeGeometry(shape);
    const nameTagMesh = new THREE.Mesh(nameTagGeometry, nameTagMaterial);

    nameTagMesh.renderOrder = 0;
    nameTag.add(nameTagMesh);
    nameTag.position.z = (22 + 62 / 2) * pixelToUnit + OUTER_RADIUS;
    this.username = nameTag;


    const cursorSphereGeometry = new THREE.SphereGeometry(INNER_RADIUS, 32, 16, undefined, undefined, 0.5 * Math.PI, 0.5 * Math.PI);
    const cursorMesh = new THREE.Mesh(cursorSphereGeometry, this.sphereMaterial);
    cursorMesh.rotation.x = Math.PI / 2;
    cursorMesh.geometry.computeBoundingSphere();

    nameGeometry.computeBoundingBox();



    const textureLoader = new THREE.TextureLoader();

    textureLoader.crossOrigin = 'anonymous';
    const url = properties.userImage ?? DEFAULT_IMAGE;

    // @ts-ignore
    const circleGeo = new THREE.RingGeometry(OUTER_RADIUS, 0.9 * INNER_RADIUS, 32);
    const metallicMaterial = new THREE.MeshPhongMaterial({ color: 0x888888, transparent: false });
    const circleMesh = new THREE.Mesh(circleGeo, metallicMaterial);
    this.circleMesh = circleMesh;


    // OUTER CIRCLE
    const outerCircleMaterial = new THREE.MeshPhongMaterial({ transparent: false, side: THREE.DoubleSide });
    // @ts-ignore
    const outerCircleGeo = new THREE.RingGeometry(OUTER_RADIUS, INNER_RADIUS + (OUTER_RADIUS - INNER_RADIUS) * 0.8, 32);
    const outerCircleMesh = new THREE.Mesh(outerCircleGeo, outerCircleMaterial);
    outerCircleMesh.renderOrder = 2;

    circleMesh.position.z -= 0.01; // Move it slightly behind
    circleMesh.renderOrder = 1;


    const backgroundMaterial = new THREE.MeshBasicMaterial({ color: 0x5F60FF, opacity: 1 });
    // @ts-ignore
    const backgroundGeometery = new THREE.CircleGeometry(INNER_RADIUS * 0.9, 32);

    const backgroundMesh = new THREE.Mesh(backgroundGeometery, backgroundMaterial);
    const circleGroup = new THREE.Group();

    backgroundMesh.renderOrder = 0;
    circleMesh.renderOrder = 1;
    backgroundMesh.position.z -= 0.01; // Move it slightly behind

    circleGroup.add(backgroundMesh);
    circleGroup.add(circleMesh);
    textureLoader.load(url, (texture) => {
      texture.minFilter = THREE.LinearFilter;
      if (url !== DEFAULT_IMAGE) {
        backgroundMaterial.opacity = 0;
      }
      this.materialWithImage = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
      circleMesh.material = this.materialWithImage;

      circleMesh.geometry.dispose();
      // @ts-ignore
      circleMesh.geometry = new THREE.CircleGeometry(INNER_RADIUS * 0.9, 32);
      if (!properties.rotation) {
        circleMesh.rotateZ(Math.PI / 2);
      }


      circleMesh.geometry.computeBoundingSphere();
    });

    circleMesh.geometry.computeBoundingSphere();

    this.cursor = new THREE.Group();
    this.cursor.add(cursorMesh);
    this.cursor.add(circleGroup);
    this.cursor.add(outerCircleMesh);
    if (properties.rotation) {
      this.cursor.rotateY(Math.PI);
    }
    this.group = new THREE.Group();
    this.group.add(this.username);
    this.group.add(this.cursor);

    this.overlays.addMesh(this.group, this.sceneId);

    this.update(properties);
  }

  public get view(): THREE.Group {
    return this.group;
  }

  getScale(camera: THREE.Camera): number {
    const distance = this.view.position.distanceTo(camera.position);
    const { far, near, orthoScale, aspect } = camera as any;
    const scalingFactor = far / Math.abs(far - near);
    const scale = scalingFactor * distance / (0.13 * distance ** 2 + distance * 3);
    const aspectRatio = aspect || 1;
    const fov = (camera as any).fov || 75; // Default FOV for perspective camera
    const normalizedOrthoScale = Math.min(orthoScale, 100); // Normalize orthoScale to a reasonable value
    const baseScale = normalizedOrthoScale / (aspectRatio * fov);
    return Math.max(scale, baseScale, 0.2);
  }

  lookAt(camera: THREE.Camera): void {
    this.username.quaternion.copy(camera.quaternion);
    const center = new THREE.Vector3();
    this.username.children.forEach((child: any) => {
      if (child.geometry) {
        child.geometry.computeBoundingBox();
        child.geometry.boundingBox?.getCenter(center);
        child.position.sub(center);
        child.geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(-center.x, -center.y, -center.z));
        child.position.add(center);
      }
    });
    const scale = this.getScale(camera);

    this.view.scale.set(scale, scale, scale);
    this.lookAtHitPoint(camera);
  }

  lookAtHitPoint(camera: THREE.Camera): void {
    const hitPoint = this.hitPointHelper.getObjectByName('hitPoint');
    if (hitPoint && this.properties.hit) {
      const { point: [hitPointX, hitPointY, hitPointZ] } = this.properties.hit;
      const hitPointVector = new THREE.Vector3(hitPointX, hitPointY, hitPointZ);
      const hitPointScale = camera.position.distanceTo(hitPointVector) / 200;
      hitPoint.scale.set(hitPointScale, hitPointScale, hitPointScale);
    }
  }

  update(data: CursorProperties, camera?: THREE.Camera): void {
    const { position, rotation, hit, forward } = data;
    if (forward && position) {
      this.view.position.set(position.x, position.y, position.z);
      this.view.position.z += INNER_RADIUS / 2;
    } else if (position) {
      this.view.position.set(position.x, position.y, position.z);
    }

    if (forward) {
      const forwardVec = new THREE.Vector3(forward.x, forward.y, forward.z);
      const up = new THREE.Vector3(0, 0, 1);
      const quaternion = new THREE.Quaternion().setFromUnitVectors(up, forwardVec.normalize());
      this.cursor.setRotationFromQuaternion(quaternion);
      const angle = Math.atan2(forward.y, forward.x);
      this.cursor.rotateZ(angle);
    } else if (rotation) {
      this.cursor.setRotationFromEuler(new THREE.Euler(rotation.x, rotation.y, rotation.z));
      this.cursor.rotateY(Math.PI);
      this.cursor.rotateZ(-Math.PI / 2);
    }

    this.updateHitPointHelper(hit, camera);

    if (camera) {
      this.lookAt(camera);
    }

    this.view.updateMatrix();
  }

  showLaserPointer(show: boolean): void {
    if (this.laserPointerIsEnabled === show) return;

    this.laserPointerIsEnabled = this.extension.showLaserPointers && show;
    const isRendered = this.overlays.hasMesh(this.hitPointHelper, this.sceneId);
    if (this.laserPointerIsEnabled && !isRendered) {
      this.overlays.addMesh(this.hitPointHelper, this.sceneId);
    } else if (!this.laserPointerIsEnabled && isRendered) {
      this.overlays.removeMesh(this.hitPointHelper, this.sceneId);
    }
  }

  updateHitPointHelper(hit?: LaserPointerHit, camera?: THREE.Camera): void {
    if (hit) {
      this.properties.hit = hit.distance > 0 ? hit : undefined;
    }

    if (this.properties.hit) {
      const { point: [hitPointX, hitPointY, hitPointZ] } = this.properties.hit;
      const hitPointVector = new THREE.Vector3(hitPointX, hitPointY, hitPointZ);

      const hitPoint = this.hitPointHelper.getObjectByName('hitPoint');
      if (hitPoint && camera) {
        if (hitPointX !== undefined && hitPointY !== undefined && hitPointZ !== undefined) {
          hitPoint.position.set(hitPointX, hitPointY, hitPointZ);
        }
        this.lookAtHitPoint(camera);
      }

      const origin = this.view.getWorldPosition(new THREE.Vector3());
      const direction = new THREE.Vector3().subVectors(hitPointVector, origin).normalize();
      const distance = origin.distanceTo(hitPointVector);
      // @ts-ignore
      const hitPointer = this.hitPointHelper.getObjectByName('arrowPointer') as THREE.ArrowHelper;
      hitPointer.position.set(origin.x, origin.y, origin.z);
      hitPointer.setDirection(direction);
      hitPointer.setLength(distance, 0, 0);
      this.showLaserPointer(true);
    } else {
      this.showLaserPointer(false);
    }

    if (hit && !hit.keep) {
      this.properties.hit = undefined;
    }
  }

  private get hitPointHelper(): THREE.Group {
    if (!this.hitPointer) {
      const zDirection = new THREE.Vector3(0, 0, 1);
      const origin = new THREE.Vector3(0, 0, 0);
      // @ts-ignore
      const arrowHelper = new THREE.ArrowHelper(zDirection, origin, 0, this.properties.color, 0, 0);
      arrowHelper.name = 'arrowPointer';

      const hitPointGeometry = new THREE.SphereGeometry(0.5, 32, 16);
      const point = new THREE.Mesh(hitPointGeometry, this.sphereMaterial);
      point.geometry.computeBoundingSphere();
      point.name = 'hitPoint';

      this.hitPointer = new THREE.Group();
      this.hitPointer.add(point);
      this.hitPointer.add(arrowHelper);
    }
    return this.hitPointer;
  }

  dispose(): void {
    this.overlays.removeMesh(this.view, this.sceneId);
    this.overlays.removeMesh(this.hitPointHelper, this.sceneId);
  }

  hide(): void {
    this.overlays.hide();
  }
}
