import Button from '@adsk/alloy-react-button/es/Button';
import { PlusIcon } from '@adsk/alloy-react-icon';
import Modal from '@adsk/alloy-react-modal/es/Modal';
import { WorkshopPermissions } from '@oasis/sdk';
import { ArrayUtils } from '@oasis/utils';
import { useMemo, useState } from 'react';
import { Label } from '~/shared/components/forms/label';
import { PermissionsSelect } from '~/shared/components/forms/permissions-select';
import { ProjectUserMultiSelect } from '~/shared/components/forms/project-user-multiselect';
import { Mutations } from '~/shared/hooks/mutations';
import { Queries } from '~/shared/hooks/queries';
import { useModal } from '~/shared/hooks/use-modal';

interface Props {
  projectId: string;
  workshopId: string;
}

export function AddUserToWorkshopModal(props: Props) {
  const modal = useModal('add-user-to-workshop');
  const [userIds, setUserIds] = useState<string[]>([]);
  const [permission, setPermission] = useState<WorkshopPermissions>('VIEW');

  const workshopMembers = Queries.Workshops.useListWorkshopMembers(props.workshopId);
  const updatePermissions = Mutations.Workshops.useUpdateWorkshopPermissions();

  async function handleSubmit() {
    updatePermissions.mutate(
      {
        workshopId: props.workshopId,
        data: {
          userIds,
          permission,
          action: 'grant',
        },
      },
      {
        onSuccess() {
          modal.close();
        },
      }
    );
  }

  const omitAutodeskIds = useMemo(() => {
    if (!workshopMembers.data) {
      return [];
    }

    return workshopMembers.data.results.members.map(user => user.autodeskId).filter(ArrayUtils.truthy);
  }, [workshopMembers.data]);

  return (
    <>
      <Button
        variant="primary"
        type="button"
        onClick={() => modal.open()}
        renderIcon={() => <PlusIcon className="mr-2" />}
      >
        Add
      </Button>

      {/* @ts-expect-error */}
      <Modal open={modal.isOpen} className="!h-auto">
        <Modal.Header>
          <h2>Add to Workshop</h2>
          <Modal.Close showEscapeLabel={false} onClick={() => modal.close()} />
        </Modal.Header>
        <Modal.Body className="!pt-6 !pb-10 !overflow-visible">
          <div>
            <Label className="mb-2">Add</Label>
            <ProjectUserMultiSelect
              projectId={props.projectId}
              workshopId={props.workshopId}
              onChange={users => setUserIds(users.map(user => user.autodeskId || ''))}
              omitAutodeskIds={omitAutodeskIds}
            />
          </div>

          <div className="mt-5">
            <Label className="mb-2">Permissions</Label>
            <PermissionsSelect value={permission} onChange={setPermission} />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center">
          <div className="space-x-2">
            <Button variant="tertiary" onClick={() => modal.close()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit} loading={updatePermissions.isPending}>
              Add
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
