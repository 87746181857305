import clsx from 'clsx';
import { ComponentProps, useMemo } from 'react';

interface Props extends ComponentProps<'div'> {
  shape?: 'circle' | 'rectangle' | 'profile';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  maxWidth?: number;
  fullWidth?: boolean;
  absoluteFill?: boolean;
  containerClassName?: string;
}

const MIN_PERCENT = 20;
const MAX_PERCENT = 60;

export function Skeleton({
  shape = 'rectangle',
  size = 'md',
  maxWidth,
  fullWidth,
  absoluteFill,
  containerClassName,
  children,
  ...props
}: Props) {
  const width = useMemo(() => {
    return shape === 'circle'
      ? undefined
      : `${Math.floor(Math.random() * (MAX_PERCENT - MIN_PERCENT + 1)) + MIN_PERCENT}%`;
  }, [shape]);

  return (
    <div
      onClick={e => e.stopPropagation()}
      className={clsx('flex items-center animate-pulse', absoluteFill && 'absolute inset-0', containerClassName)}
      style={{
        width: fullWidth || absoluteFill ? '100%' : width,
        maxWidth: maxWidth && !fullWidth ? `${maxWidth / 16}rem` : undefined,
      }}
    >
      {shape === 'profile' && <div className="rounded-full w-6 h-6 bg-charcoal-100 mr-2" />}

      <div
        {...props}
        className={clsx(
          'w-full bg-charcoal-100',
          absoluteFill && 'absolute inset-0 w-full h-full',
          // circle
          shape === 'circle' && 'rounded-full',
          shape === 'circle' && size === 'sm' && 'w-4 h-4',
          shape === 'circle' && size === 'md' && 'w-6 h-6',
          shape === 'circle' && size === 'lg' && 'w-7 h-7',
          shape === 'circle' && size === 'xl' && 'w-8 h-8',
          // rectangle
          shape !== 'circle' && 'rounded-sm',
          shape !== 'circle' && size === 'sm' && 'h-3',
          shape !== 'circle' && size === 'md' && 'h-4',
          shape !== 'circle' && size === 'lg' && 'h-5',
          shape !== 'circle' && size === 'xl' && 'h-6',
          // props
          props.className
        )}
      />

      {children}
    </div>
  );
}
