import {
  AlertInformationIcon,
  DesktopIcon,
  GearToolsIcon,
  MoreVerticalIcon,
  PencilIcon,
  PersonGearIcon,
  TrashCanIcon,
} from '@adsk/alloy-react-icon';
import { Oasis } from '@oasis/sdk';
import { Confirmation } from '~/shared/components/base/confirmation';
import { Popover } from '~/shared/components/base/popover';
import { HeadsetIcon } from '~/shared/components/icons/headset-icon';
import { WorkshopSettingsIcon } from '~/shared/components/icons/workshop-settings-icon';
import { Mutations } from '~/shared/hooks/mutations';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';
import { useOpenModal } from '~/shared/hooks/use-modal';
import { MicIcon } from '~/shared/layouts/project/components/voice-manager/icons/mic';
import { useWorkshopDetails } from '../../hooks/use-workshop-details';

interface Props {
  id: string;
  name: string;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  canEdit?: boolean;
  canManage?: boolean;
}

const BLOB_CONTENT = `Opts:
  mode: replay # Options are replay or record
  file: ./recordings/replay.csv # Path to file to replay from or recoed to (Note: file will be overriden)
  encode_payload: True # Whether to base64 the payload or save as is in the file
  log_level: INFO
  thread_count: 1 # Number of concurrent threads to replay
  topics: # List of topics to record from
    - wsq/v1/cmd/<USER_ID>/#
    - wsq/v1/ws0/<WORKSHOP_ID>/#
    - wsq/v1/ws1/<WORKSHOP_ID>/#

MqttOpts:
  host: mqtt-dev.forgexr-c-uw2.autodesk.com
  port: 443
  tls_opts:
    enabled: True`;

export function WorkshopOverflowMenu(props: Props) {
  const $env = Oasis.Env.useStore();
  const $session = Oasis.Session.useStore();
  const [enableDesktop, showClearAnnotations] = useFeatureFlags([
    '5465-enter-on-desktop',
    'OASIS-6824-clear-all-annotations',
  ]);
  const openModal = useOpenModal();
  const { showPanel } = useWorkshopDetails();

  const deleteWorkshop = Mutations.Workshops.useDeleteWorkshop();
  const openWorkshop = Mutations.Workshops.useOpenWorkshopOnDevice();

  if ($session.license.type === 'FREE_VIEWER') {
    return null;
  }

  return (
    <Popover>
      <Popover.Trigger onClick={e => e.stopPropagation()} className="opacity-50 hover:opacity-100">
        <div data-testid="workshop-overflow-menu-trigger">
          <MoreVerticalIcon />
        </div>
      </Popover.Trigger>

      <Popover.Content side="bottom" align="end" sideOffset={8} hideArrow>
        {$env.isVr ? (
          <Popover.Button
            icon={<HeadsetIcon className="w-6 h-6" />}
            onClick={e => {
              e.stopPropagation();
              openWorkshop.mutate({ device: Oasis.Env.store.vrTargetDevice, id: props.id });
            }}
          >
            Enter
          </Popover.Button>
        ) : (
          <Popover.Button
            icon={<HeadsetIcon className="w-6 h-6" />}
            onClick={e => {
              e.stopPropagation();
              openModal('open-workshop-headset', { workshopId: props.id, workshopName: props.name });
            }}
          >
            Enter on headset
          </Popover.Button>
        )}

        {!$env.isVr && enableDesktop && (
          <Popover.Button
            icon={<DesktopIcon size={24} />}
            onClick={e => {
              e.stopPropagation();
              openModal('open-workshop-desktop', { workshopId: props.id, workshopName: props.name });
            }}
          >
            Enter on desktop
          </Popover.Button>
        )}

        <hr className="border-t border-charcoal-100 my-1" />

        <Popover.Button
          icon={<AlertInformationIcon />}
          onClick={e => {
            e.stopPropagation();
            showPanel(props.id, 'details');
          }}
        >
          Workshop details
        </Popover.Button>

        {props.canManage && showClearAnnotations && (
          <Popover.Button
            icon={<WorkshopSettingsIcon />}
            onClick={e => {
              e.stopPropagation();
              showPanel(props.id, 'settings');
            }}
          >
            Workshop settings
          </Popover.Button>
        )}

        {props.canManage && (
          <Popover.Button
            icon={<PersonGearIcon />}
            onClick={e => {
              e.stopPropagation();
              showPanel(props.id, 'permissions');
            }}
          >
            Permission settings
          </Popover.Button>
        )}

        {props.canEdit && (
          <>
            <Popover.Button
              icon={<PencilIcon />}
              onClick={e => {
                e.stopPropagation();
                props.setIsEditing(true);
              }}
            >
              Rename
            </Popover.Button>

            <Popover.Button
              destructive
              icon={<TrashCanIcon />}
              onClick={e => {
                e.stopPropagation();

                Confirmation.show({
                  title: 'Delete workshop?',
                  description:
                    "This action cannot be undone. Deleting this workshop won't delete any of your files or issues.",
                  confirmLabel: 'Delete',
                  confirm: () => deleteWorkshop.mutate({ id: props.id, name: props.name }),
                });
              }}
            >
              Delete workshop
            </Popover.Button>
          </>
        )}

        {$env.isDevMode && (
          <>
            <hr className="border-t border-charcoal-100 my-1" />

            <Popover.Button
              icon={<MicIcon />}
              onClick={e => {
                e.stopPropagation();
                Oasis.Voice.connectToWorkshop({ workshopId: props.id });
              }}
            >
              <p>Join voice</p>
              <p className="ml-auto text-[10px] uppercase text-yellorange-500 leading-tight">Dev mode only</p>
            </Popover.Button>

            <Popover.Button
              icon={<GearToolsIcon />}
              onClick={e => {
                e.stopPropagation();

                const blob = new Blob(
                  [BLOB_CONTENT.replaceAll('<USER_ID>', $session.user?.id ?? '').replaceAll('<WORKSHOP_ID>', props.id)],
                  { type: 'text/plain' }
                );
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
                URL.revokeObjectURL(url);
              }}
            >
              <p>Replay config</p>
              <p className="ml-auto text-[10px] uppercase text-yellorange-500 leading-tight">Dev mode only</p>
            </Popover.Button>
          </>
        )}
      </Popover.Content>
    </Popover>
  );
}
