import { useMemo } from 'react';
import { useWorkshopAudience } from '~/shared/hooks/use-workshop-audience';

export function FollowingBorder() {
  const $workshopAudience = useWorkshopAudience();

  const host = useMemo(() => {
    const host = $workshopAudience.hostUser;

    if (host) {
      return `${host.additionalDetails.userName} ${host.additionalDetails.userLastName}`;
    }
  }, [$workshopAudience.hostUser]);

  if (!host) {
    return null;
  }

  return (
    <div className="absolute inset-0 flex-1 border-[3px] border-blue-300 flex items-start justify-center pointer-events-none">
      {/* the `-ml-6` bumps it over to account for the page's navigation */}
      <p className="absolute left-1/2 top-0 -translate-x-1/2 -ml-6 px-2 pb-1 rounded-b bg-blue-300 text-label-sm">
        Following <span className="font-bold">{host}</span>
      </p>
    </div>
  );
}
